import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import {BmUnstyledSvgButton} from "~/components/buttons";
import {useAuth} from "~/hooks";
import {BmLogoutIcon} from "~/components/icons/simpleIcons";
import {designColors, designTokens, designUtils} from "~/ui";
import {useAppSelector, strongSelectMaker, selectUser} from "~/modules/redux";

interface SidebarUserProps {}

export const SidebarUser: React.FC<SidebarUserProps> = () => {
  const maker = useAppSelector(strongSelectMaker);
  const {user} = useAppSelector(selectUser);
  const [, , resetAuth] = useAuth();

  return (
    <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}} mx={1}>
      <Box sx={{maxWidth: 3 / 4}}>
        <Typography
          className={designUtils.textOverflow}
          color={designColors.white}
          fontSize={designTokens.fontSize.sm}
          fontWeight={designTokens.fontWeight.extraBold}
        >
          {maker?.tradeName || maker?.name}
        </Typography>
        {user?.name && (
          <Typography sx={{fontSize: designTokens.fontSize.xsm}} color={designColors.white}>
            {user.name}
          </Typography>
        )}
      </Box>

      <BmUnstyledSvgButton onClick={resetAuth} id="logout-icon">
        <BmLogoutIcon id="logout-icon" color={designColors.white} size="xs" />
      </BmUnstyledSvgButton>
    </Box>
  );
};
