import {createSlice} from "@reduxjs/toolkit";

import {ILocalExtraProduct} from "~/model/DAO/IExtraProduct";

import {resetAll} from "./global";

export interface LocalExtraProductState {
  localExtraProduct: ILocalExtraProduct;
  shouldCreateNewOnSubmit: boolean;
}

const initialState: LocalExtraProductState = {
  localExtraProduct: {} as ILocalExtraProduct,
  shouldCreateNewOnSubmit: false,
};

export const LocalExtraProductSlice = createSlice({
  name: "localExtraProduct",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setStep: (state, action) => ({
      ...state,
      localExtraProduct: {
        ...state.localExtraProduct,
        ...action.payload,
      },
    }),
    resetAllSteps: (state) => ({
      ...state,
      localExtraProduct: {...initialState.localExtraProduct},
    }),
    toggleShouldCreateNewOnSubmit: (state) => ({
      ...state,
      shouldCreateNewOnSubmit: !state.shouldCreateNewOnSubmit,
    }),
  },
});

export const LocalExtraProductActions = LocalExtraProductSlice.actions;

export default LocalExtraProductSlice.reducer;
