import MuiTableCell from "@mui/material/TableCell";

import {styles} from "./TableCellFull.styles";

interface BmTableCellFullProps {
  children?: React.ReactNode;
}
export const BmTableCellFull: React.FC<BmTableCellFullProps> = ({children}) => {
  return <MuiTableCell className={styles.tableCellFull}>{children}</MuiTableCell>;
};
