import {css} from "@emotion/css";

export const styles = {
  skeleton: css`
    &.MuiSkeleton-root {
      border-radius: 10px;
      width: 100%;
      transform: scale(1, 1);
    }
  `,
};
