import React, {useMemo, useState} from "react";
import {Collapse, List, Tooltip} from "@mui/material";
import {useRouteMatch} from "react-router-dom";

import {useAppDispatch, useAppSelector, UiActions} from "~/modules/redux";
import {useDidMount} from "~/hooks";

import {styles} from "./SidebarSubList.styles";
import {SidebarItem} from "../SidebarItem/SidebarItem";
import {SidebarItemContent} from "../SidebarItemContent/SidebarItemContent";

type SidebarSubListProps = {
  id: string;
  title: string;
  path: string | string[];
  tooltipTitle: string;
  icon: React.ReactElement;
  children: React.ReactNode;
  disabled?: boolean;
};

export const SidebarSubList: React.FC<SidebarSubListProps> = ({
  id,
  title,
  path,
  icon,
  tooltipTitle,
  children,
  disabled,
}) => {
  const match = useRouteMatch(path);
  const [isOpenLocal, setIsOpenLocal] = useState<boolean>(!!match);
  const {openedSubMenu} = useAppSelector((state) => state.ui);
  const dispatch = useAppDispatch();

  const isOpen = useMemo(() => isOpenLocal || openedSubMenu === id, [id, isOpenLocal, openedSubMenu]);

  useDidMount(() => {
    if (!!match) {
      dispatch(UiActions.openSidebarSubList(id));
      setIsOpenLocal(false);
    }
    return () => {
      dispatch(UiActions.openSidebarSubList(null));
      setIsOpenLocal(false);
    };
  });

  const menuToggle = () => {
    if (disabled) return;
    dispatch(UiActions.toggleSidebarSubList(id));
  };

  return (
    <>
      <SidebarItem onClick={menuToggle} disabled={disabled}>
        <Tooltip title={disabled ? tooltipTitle : ""}>
          <SidebarItemContent text={title} icon={icon} hasChildren isOpen={isOpen} />
        </Tooltip>
      </SidebarItem>
      <Collapse className={styles.collapse} in={isOpen} timeout="auto" unmountOnExit>
        <List className={styles.sidebarSubList} component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
};
