import {cx} from "@emotion/css";

import {ReactComponent as SvgCheckboxEmpty} from "~/assets/svg/checkbox-empty.svg";
import {designColors} from "~/ui";

import {IIconBase} from "../../_common/types";
import {commonStyles} from "../../_common/styles";
import {simpleIconStyles} from "../styles";

export const BmCheckboxEmptyIcon: React.FC<IIconBase> = ({id, size = "lg", color = designColors.grey_4, className}) => {
  return (
    <SvgCheckboxEmpty id={id} className={cx(simpleIconStyles(size), commonStyles.strokeColor(color), className)} />
  );
};
