import {css} from "@emotion/css";

import {designColors, designTokens} from "~/ui";

export const styles = {
  breadcrumbs: css`
    .MuiBreadcrumbs-ol {
      align-items: flex-start;
      flex-wrap: nowrap;
    }
  `,
  breadcrumb: css`
    color: ${designColors.black};
    font-size: ${designTokens.fontSize.sm};
    font-weight: ${designTokens.fontWeight.regular};
    line-height: 1.385;
  `,
  active: css`
    text-decoration: none;
  `,
};
