import {css} from "@emotion/css";

export const commonStyles = {
  color: (color: string) => css`
    *[fill^="\#"] {
      fill: ${color};
    }
    *[stroke^="\#"] {
      stroke: ${color};
    }
  `,
  fillColor: (fillColor: string) => css`
    *[fill^="\#"] {
      fill: ${fillColor};
    }
  `,
  strokeColor: (strokeColor: string) => css`
    *[stroke^="\#"] {
      stroke: ${strokeColor};
    }
  `,
  strokeWidth: (strokeWidth: string) => css`
    *[stroke^="\#"] {
      stroke-width: ${strokeWidth};
    }
  `,
};
