import {ZendeskAPI} from "react-zendesk";
import {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

import {useAppSelector, strongSelectMaker} from "~/modules/redux";
import {useInterval} from "~/hooks";

export const useZendeskSuggestedPosts = () => {
  const maker = useAppSelector(strongSelectMaker);
  const {pathname} = useLocation();
  const [zwwTitle, setZwwTitle] = useState("");
  const [zendeskRelatedLabels, setZendeskRelatedLabels] = useState("");

  const getZwwSuggestions = useCallback(() => {
    const currentZwwTitle = document.querySelector(".zwwTitle");
    const currentZendeskRelatedLabels = document.querySelector("meta[name=keywords]");

    if (!currentZwwTitle && !currentZendeskRelatedLabels) return;
    if (currentZendeskRelatedLabels?.getAttribute("content")) {
      setZendeskRelatedLabels(currentZendeskRelatedLabels.getAttribute("content")!);
    } else {
      setZwwTitle(currentZwwTitle!.textContent || "");
    }
  }, []);

  const interval = useInterval(getZwwSuggestions, 500);

  useEffect(() => {
    getZwwSuggestions();
    interval.start();
    return () => interval.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (!zwwTitle && !zendeskRelatedLabels) return;

    if (zendeskRelatedLabels) {
      ZendeskAPI("webWidget", "helpCenter:setSuggestions", {labels: zendeskRelatedLabels.split(", ")});
    } else {
      ZendeskAPI("webWidget", "helpCenter:setSuggestions", {search: zwwTitle});
    }

    interval.stop();
  }, [interval, maker, zendeskRelatedLabels, zwwTitle]);
};
