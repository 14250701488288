import {cx} from "@emotion/css";

import {designColors} from "~/ui";
import {ReactComponent as SvgEdit} from "~/assets/svg/edit.svg";

import {simpleIconStyles} from "../styles";
import {commonStyles} from "../../_common/styles";
import {IIconBase} from "../../_common/types";

export const BmEditIcon: React.FC<IIconBase> = ({id, size = "sm", color = designColors.black, className}) => {
  return <SvgEdit id={id} className={cx(simpleIconStyles(size), commonStyles.color(color), className)} />;
};
