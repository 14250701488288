import {css} from "@emotion/css";

import {designColors} from "~/ui";

export const styles = {
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${designColors.white};
  `,
};
