import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {OrderItemSummaryFragment} from "~/api/generated";
import {ILocalOrder} from "~/model/DAO/IOrder";

import {resetAll} from "./global";

export interface LocalOrderState {
  localOrder: ILocalOrder;
  isLocalOrderEdited: boolean;
}

const initialState: LocalOrderState = {
  localOrder: {} as ILocalOrder,
  isLocalOrderEdited: false,
};

export const OrderSlice = createSlice({
  name: "localOrder",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    resetEdited: (state) => {
      state.isLocalOrderEdited = false;
    },
    setOrder: (state, action: PayloadAction<ILocalOrder>) => {
      state.isLocalOrderEdited = false;
      state.localOrder = action.payload;
    },
    updateOrder: (state, action: PayloadAction<Partial<ILocalOrder>>) => {
      state.isLocalOrderEdited = true;
      state.localOrder = {
        ...state.localOrder,
        ...action.payload,
      };
    },
    resetOrder: () => initialState,
    setCustomer: (state, action: PayloadAction<ILocalOrder["customer"]>) => {
      state.isLocalOrderEdited = true;
      state.localOrder = {
        ...state.localOrder,
        customer: action.payload,
      };
    },
    deleteCustomer: (state) => {
      state.isLocalOrderEdited = true;
      state.localOrder = {
        ...state.localOrder,
        prevCustomer: state.localOrder.customer,
        customer: undefined,
        shippingAddress: undefined,
        billingAddress: undefined,
        shippingCarrier: undefined,
        shippingPackage: undefined,
        deliveryMethod: undefined,
        location: null,
      };
    },
    setWarehouse: (state, action: PayloadAction<ILocalOrder["warehouse"]>) => {
      state.localOrder.warehouse = action.payload;
    },
    resetWarehouse: (state) => {
      state.localOrder.warehouse = undefined;
    },
    setOrderItem: (state, action: PayloadAction<OrderItemSummaryFragment>) => {
      state.isLocalOrderEdited = true;
      state.localOrder.shippingWeight = undefined;
      state.localOrder.shippingPackage = null;
      state.localOrder = {
        ...state.localOrder,
        orderItems: [...(state.localOrder.orderItems || []), action.payload],
      };
    },
    updateOrderItem: (state, action: PayloadAction<OrderItemSummaryFragment>) => {
      state.isLocalOrderEdited = true;
      state.localOrder.shippingWeight = undefined;
      state.localOrder.shippingPackage = null;
      const orderItemIndex = state.localOrder.orderItems!.findIndex((orderItem) => orderItem.id === action.payload.id);
      state.localOrder.orderItems!.splice(orderItemIndex, 1, action.payload);
    },
    deleteOrderItem: (state, action: PayloadAction<number>) => {
      state.isLocalOrderEdited = true;
      state.localOrder.shippingWeight = undefined;
      state.localOrder.shippingPackage = null;
      const orderItemIndex = action.payload;
      state.localOrder.orderItems!.splice(orderItemIndex, 1);
      if (state.localOrder.orderItems!.length === 0) {
        state.localOrder.orderItems = undefined;
      }
    },
    deleteAllOrderItems: (state) => {
      state.isLocalOrderEdited = true;
      state.localOrder.shippingWeight = undefined;
      state.localOrder.shippingPackage = null;
      state.localOrder.orderItems = undefined;
    },
    setCurrency: (state, action: PayloadAction<string>) => {
      state.localOrder = {
        ...state.localOrder,
        orderItems: state.localOrder.orderItems?.map((item) => ({
          ...item,
          price: {
            ...item.price,
            currency: action.payload,
          },
          totalPrice: {
            ...item.totalPrice,
            currency: action.payload,
          },
        })),
      };
    },
    resetServicePoint: (state) => {
      state.localOrder = {
        ...state.localOrder,
        shippingServicePoint: undefined,
      };
    },
  },
});

export const OrderActions = OrderSlice.actions;

export default OrderSlice.reducer;
