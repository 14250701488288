import React, {FC} from "react";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {cx} from "@emotion/css";

import {styles} from "~/pages/notFound/container/NotFoundContent/NotFoundContent.styles";
import {BmCartonBox, BmGrayBg} from "~/components/icons/otherIcons";
import {BmPageTitle} from "~/components/titles";
import {BmPrimaryRouterLink} from "~/components/links";
import {HOME_PATH} from "~/modules/routing";

type NotFoundContentProps = {
  className?: string;
};

export const NotFoundContent: FC<NotFoundContentProps> = ({className}) => {
  const {t} = useTranslation();
  return (
    <Box className={cx(styles.container, className)}>
      <Box className={styles.content}>
        <BmGrayBg id="gray-bg-icon" className={styles.bg} />
        <BmCartonBox id="box-icon" className={styles.image} />
        <Box className={styles.text}>
          <BmPageTitle sx={{mb: 2}}>{t("not_found.title")}</BmPageTitle>
          <Box sx={{mb: 8}}>{t("not_found.description")}</Box>
          <BmPrimaryRouterLink to={HOME_PATH}>{t("not_found.link")}</BmPrimaryRouterLink>
        </Box>
      </Box>
    </Box>
  );
};
