import {SxProps, Theme, Box} from "@mui/material";

export interface VerticalAlignBetweenProps {
  className?: string;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

export const VerticalAlignBetween: React.FC<VerticalAlignBetweenProps> = ({className, sx, children}) => (
  <Box
    className={className}
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: "100%",
      ...sx,
    }}
  >
    {children}
  </Box>
);
