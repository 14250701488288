import {cx} from "@emotion/css";

import {designColors} from "~/ui";
import {ReactComponent as SvgEnergy} from "~/assets/svg/energy.svg";

import {simpleIconStyles} from "../styles";
import {commonStyles} from "../../_common/styles";
import {IIconBase} from "../../_common/types";

export const BmEnergyIcon: React.FC<IIconBase> = ({id, size = "xs", color = designColors.black, className}) => {
  return <SvgEnergy id={id} className={cx(simpleIconStyles(size), commonStyles.color(color), className)} />;
};
