import {equals} from "ramda";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";

import {useLazyCurrentUserQuery} from "~/api/generated";
import {selectMaker, useAppDispatch, useAppSelector, UserActions} from "~/modules/redux";
import {useAuth} from "~/hooks";

export const useCurrentUser = (): [boolean, boolean] => {
  const [isAuthorized] = useAuth();
  const {pathname} = useLocation();
  const [getCurrentUser, {data, isError, isSuccess, isLoading}] = useLazyCurrentUserQuery();
  const maker = useAppSelector(selectMaker);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isAuthorized) return;
    getCurrentUser();
  }, [getCurrentUser, isAuthorized, pathname]);

  useEffect(() => {
    if (isLoading) return;
    if (!isSuccess) return;
    if (!data?.current) return;

    const {impersonation, currentUser, currentMaker: nextMaker} = data.current;
    if (equals(maker, nextMaker)) return;

    dispatch(UserActions.setImpersonation(impersonation));
    dispatch(UserActions.setUser(currentUser));
    dispatch(UserActions.setMaker(nextMaker));
  }, [data, dispatch, isLoading, isSuccess, maker]);

  return [isSuccess, isError];
};
