import {injectGlobal} from "@emotion/css";

import {designColors, designConstants, designTokens, designUtils} from ".";

injectGlobal`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-size: ${designConstants.remSize};
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    ${designUtils.baseFontStyles};
    font-family: ${designTokens.fontFamily};
    font-weight: ${designTokens.fontWeight.regular};
    color: ${designColors.colorInk};
    text-align: left;
    background-color: ${designColors.white};

    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  b,
  strong {
    font-weight: ${designTokens.fontWeight.bold};
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  svg {
    overflow: hidden;
    vertical-align: middle;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;
