import {createSlice} from "@reduxjs/toolkit";

import {CurrentMakerSummaryFragment, CurrentUserSummaryFragment} from "~/api/generated";

import {resetAll} from "./global";

export interface UserState {
  impersonation: Boolean;
  maker: CurrentMakerSummaryFragment | null | undefined;
  user: CurrentUserSummaryFragment | undefined;
  isSuccessfullyCreatedCompanyMessageVisible: boolean;
  makerHasDigitalProducts?: boolean;
}

const initialState: UserState = {
  impersonation: false,
  maker: undefined,
  user: undefined,
  isSuccessfullyCreatedCompanyMessageVisible: false,
  makerHasDigitalProducts: undefined,
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setImpersonation: (state, action) => {
      state.impersonation = action.payload;
    },
    setMaker: (state, action) => {
      state.maker = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    showSuccessfullyCreatedCompanyMessage: (state) => {
      state.isSuccessfullyCreatedCompanyMessageVisible = true;
    },
    hideSuccessfullyCreatedCompanyMessage: (state) => {
      state.isSuccessfullyCreatedCompanyMessageVisible = false;
    },
    setMakerHasDigitalProducts: (state, action) => {
      state.makerHasDigitalProducts = action.payload;
    },
  },
});

export const UserActions = UserSlice.actions;

export default UserSlice.reducer;
