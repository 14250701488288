import {cx} from "@emotion/css";
import MuiIconButton from "@mui/material/IconButton";

import {BmMenuIcon} from "~/components/icons/simpleIcons";
import {designColors} from "~/ui";

import {IButton} from "../IButton";
import {styles} from "./MenuButton.styles";

export const BmMenuButton: React.FC<IButton> = ({className, sx, onClick}) => {
  return (
    <MuiIconButton onClick={onClick} size="medium" className={cx(styles.menuButton, className)} sx={sx}>
      <BmMenuIcon id="menu-icon" color={designColors.white} />
    </MuiIconButton>
  );
};
