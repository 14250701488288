import {css} from "@emotion/css";

export const styles = {
  tableCellFull: css`
    &.MuiTableCell-root {
      display: block;
      border-bottom: 0;
      vertical-align: top;
      height: auto;
      padding: 11px 6px;
      padding-left: 0;
      width: auto !important;
    }
  `,
};
