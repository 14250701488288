import {createContext, useContext} from "react";

import {noop} from "~/utils/noop";

import {SortingType} from "./ITableSorting";

const BmTableSortingContext = createContext<SortingType>({
  sortList: [],
  sortDirection: undefined,
  sortBy: undefined,
  buttonText: "",
  sortToggle: noop,
});

export const BmTableSortingContextProvider = BmTableSortingContext.Provider;

export const useBmTableSortingContext = () => useContext(BmTableSortingContext);
