import {css} from "@emotion/css";

import {designColors} from "~/ui";
import {theme} from "~/ui/theme";

export const styles = {
  toaster: css`
    &.Toastify__toast-container--top-right {
      top: 80px;
      right: 30px;
    }
    .Toastify__toast {
      background: transparent;
      min-height: 50px;
      padding: 0;
      ${theme.breakpoints.down("sm")} {
        margin: 0 20px;
      }
      &.Toastify__zoom-enter {
        animation-duration: 0.2s;
      }
      &-body {
        padding: 0;
        transition: none !important;
      }
    }
  `,
  successColor: css`
    &.MuiAlert-root {
      background-color: ${designColors.black};
      svg {
        fill: ${designColors.green_2};
      }
    }
  `,
  errorColor: css`
    &.MuiAlert-root {
      background-color: ${designColors.black};
      svg {
        fill: ${designColors.red_2};
      }
    }
  `,
  infoColor: css`
    &.MuiAlert-root {
      background-color: ${designColors.black};
      svg {
        fill: ${designColors.blue_1};
      }
    }
  `,
  simpleToast: css`
    &.MuiAlert-root {
      background-color: ${designColors.black};
      justify-content: center;
    }
  `,
};
