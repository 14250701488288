import {createApi} from "@reduxjs/toolkit/query/react";
import {GraphQLClient} from "graphql-request";

import {graphqlRequestBaseQuery} from "./graphqlRequestBaseQuery";

export const client = new GraphQLClient(`${process.env.BEMAKERS_API_URL}/graphql`, {
  headers: {
    authorization: `Bearer ${window.localStorage.getItem("TOKEN")}`,
  },
});
export const api = createApi({
  reducerPath: "api",
  baseQuery: graphqlRequestBaseQuery({client}),
  endpoints: () => ({}),
});
