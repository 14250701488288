import {css} from "@emotion/css";

import {theme} from "~/ui/theme";

export const styles = {
  root: css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: auto;
    padding: 12px;

    ${theme.breakpoints.up("sm")} {
      padding: 12px 24px;
    }

    ${theme.breakpoints.up("md")} {
      padding: 12px 0;
    }
  `,
};
