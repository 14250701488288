import React from "react";
import MuiModal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import {useTranslation} from "react-i18next";
import Stack from "@mui/material/Stack";

import {TRANSITION_TIME} from "~/constants/constants";
import {BmSecondaryButton, BmPrimaryButton} from "~/components/buttons";
import {BmCommonTitleSmall} from "~/components/titles";

import {styles} from "./DialogModal.styles";

export interface BmDialogModalProps {
  open: boolean;
  loading?: boolean;
  loadingIndicator?: string;
  confirmButtonText: string;
  defaultTitle?: boolean;
  onConfirmButtonClick: () => void;
  onClose: VoidFunction;
  title?: React.ReactNode | string;
  content?: string;
}

export const BmDialogModal: React.FC<BmDialogModalProps> = ({
  open,
  loading,
  loadingIndicator,
  confirmButtonText,
  defaultTitle = true,
  onConfirmButtonClick,
  onClose,
  title,
  content,
}) => {
  const {t} = useTranslation();

  return (
    <MuiModal open={open} onClose={onClose} className={styles.modalWrap}>
      <Fade in={open} timeout={TRANSITION_TIME}>
        <Box className={styles.modal}>
          <Box sx={{mb: 2.5}}>
            {defaultTitle ? <BmCommonTitleSmall className={styles.modalTitle}>{title}</BmCommonTitleSmall> : title}
          </Box>
          {content && <div className={styles.modalText} dangerouslySetInnerHTML={{__html: content}} />}
          <Stack direction="row" gap={2} mt={4}>
            <BmPrimaryButton onClick={onConfirmButtonClick} loading={loading} loadingIndicator={loadingIndicator}>
              {confirmButtonText}
            </BmPrimaryButton>
            <BmSecondaryButton onClick={onClose}>{t("buttons.cancel")}</BmSecondaryButton>
          </Stack>
        </Box>
      </Fade>
    </MuiModal>
  );
};
