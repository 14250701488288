import {equals} from "ramda";
import {useCallback, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {useLocalStorage, useDidMount} from "~/hooks";

import {ITablePaginationCallbacks, ITablePaginationParams, PaginationDirectionType} from "./ITablePagination";
import {DEFAULT_PER_PAGE, perPageProtector} from "./TablePagination";

type PaginationStateParams = Omit<ITablePaginationParams, "sortDirection" | "sortBy">;

type UseTablePaginationFromStateReturnTuple = [PaginationStateParams, ITablePaginationCallbacks, VoidFunction];

const initPaginationStateParams: PaginationStateParams = {
  perPage: DEFAULT_PER_PAGE,
  startCursor: undefined,
  endCursor: undefined,
};

export const useTablePaginationFromState = (): UseTablePaginationFromStateReturnTuple => {
  const history = useHistory();
  const location = useLocation();
  const [paginationStateParams, setPaginationStateParams] = useState<PaginationStateParams>(initPaginationStateParams);
  const [storedPerPage, storePerPage] = useLocalStorage("perPage");

  const onPageChange = useCallback((direction: PaginationDirectionType, nextCursor: string) => {
    setPaginationStateParams((prevPaginationStateParams) => {
      return {
        ...prevPaginationStateParams,
        startCursor: direction === "next" ? undefined : nextCursor,
        endCursor: direction === "next" ? nextCursor : undefined,
      };
    });
  }, []);

  const onRowsPerPageChange = useCallback(
    (nextRowsPerPage: number) => {
      const strNextRowsPerPage = String(nextRowsPerPage);
      storePerPage(strNextRowsPerPage);

      setPaginationStateParams({
        ...initPaginationStateParams,
        perPage: nextRowsPerPage,
      });
    },
    [storePerPage],
  );

  const resetCursor = useCallback(() => {
    setPaginationStateParams((prevPaginationStateParams) => ({
      ...initPaginationStateParams,
      perPage: prevPaginationStateParams.perPage,
    }));
  }, []);

  useDidMount(() => {
    if (!storedPerPage) return;
    const protectedStoredPerPage = perPageProtector(storedPerPage);
    if (protectedStoredPerPage === DEFAULT_PER_PAGE) return;
    onRowsPerPageChange(protectedStoredPerPage);
  });

  useEffect(() => {
    const initPathname = location.pathname;
    const initSearch = location.search;

    const unlisten = history.listen(({pathname, search}: any) => {
      if (equals(initPathname, pathname) && !equals(initSearch, search)) return;
      setPaginationStateParams(initPaginationStateParams);
    });
    return () => unlisten();
  }, [history, location.pathname, location.search]);

  return [paginationStateParams, {onPageChange, onRowsPerPageChange}, resetCursor];
};
