import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const stockMovementApi = createApi({
  reducerPath: "stockMovementApi",
  baseQuery: fetchBaseQuery({baseUrl: process.env.BEMAKERS_API_URL}),
  endpoints: (build) => ({
    getStockMovement: build.query({
      query: (token) => ({
        url: `/stock_movements/${token}`,
        method: "GET",
      }),
    }),
    confirmStockMovement: build.mutation({
      query: ({token, items}) => ({
        url: `/stock_movements/${token}`,
        method: "PUT",
        body: {items: items},
      }),
    }),
  }),
});

export const {useGetStockMovementQuery, useConfirmStockMovementMutation} = stockMovementApi;
