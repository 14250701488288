import Box from "@mui/material/Box";

import {styles} from "./SidebarItemBadge.styles";

interface SidebarItemBadgeProps {
  children: React.ReactNode;
}

export const SidebarItemBadge: React.FC<SidebarItemBadgeProps> = ({children}) => {
  return (
    <Box component="span" className={styles.root}>
      {children}
    </Box>
  );
};
