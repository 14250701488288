import {useAuth} from "~/hooks";

import NotFoundPrivate from "./NotFoundPrivate";
import NotFoundPublic from "./NotFoundPublic";

const NotFound: React.FC = () => {
  const [isAuth] = useAuth();
  return <>{isAuth ? <NotFoundPrivate /> : <NotFoundPublic />}</>;
};

export default NotFound;
