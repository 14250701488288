import {css} from "@emotion/css";

import {designTokens} from "~/ui/designTokens";

export const styles = {
  root: css`
    &.MuiButton-root {
      min-width: 212px;
      height: 51px;

      font-size: 1rem;
      font-weight: ${designTokens.fontWeight.regular};
      text-transform: none;

      &,
      &:hover {
        box-shadow: none;
      }
    }
  `,
};
