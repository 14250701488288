import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";

import {IToast} from "../IToast";
import {styles} from "../Toast/Toaster.styles";

export const BmErrorToast: React.FC<IToast> = ({message}) => {
  return (
    <MuiAlert className={styles.errorColor} severity="error" variant="filled">
      <Box dangerouslySetInnerHTML={{__html: message}} />
    </MuiAlert>
  );
};
