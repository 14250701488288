import {CompanySummaryFragment} from "~/api/generated";
import {enhanceImageUrl} from "~/utils/images";

export const enhanceCompanyData = (companyData: CompanySummaryFragment): CompanySummaryFragment => {
  return {
    ...companyData,
    logoUrl: enhanceImageUrl(companyData.logoUrl),
    printLogoUrl: enhanceImageUrl(companyData.printLogoUrl),
    faviconUrl: enhanceImageUrl(companyData.faviconUrl, {noTransformations: true}),
  };
};
