import {Box, Stack} from "@mui/material";

import {BmPageTitle} from "~/components/titles";

import {IModalContent} from "../IModalContent";

export const BmStepperModalContent: React.FC<IModalContent> = ({title, children}) => {
  return (
    <Stack spacing={4} p={4} useFlexGap>
      <BmPageTitle sx={{m: 0}}>{title}</BmPageTitle>
      <Box>{children}</Box>
    </Stack>
  );
};
