import {css} from "@emotion/css";

import {designTokens} from "~/ui";

export const styles = {
  sidebarSubItemLink: css`
    width: 100%;
    text-decoration: none;
  `,
  sidebarSubItemContent: css`
    padding: 5px 12px;

    .MuiListItemText-root .list-item-text {
      font-size: ${designTokens.fontSize.xsm};
      font-weight: ${designTokens.fontWeight.medium};
      letter-spacing: 0.46px;
    }
  `,
};
