import {BmInfoPageLayout} from "~/layouts/pageLayouts";
import {BmLayoutSkeleton} from "~/components/skeleton";

export const SharedRouteFallback: React.FC = () => {
  return (
    <BmInfoPageLayout gridSize={10}>
      <BmLayoutSkeleton />
    </BmInfoPageLayout>
  );
};
