import {cx} from "@emotion/css";

import {ReactComponent as SvgRadioChecked} from "~/assets/svg/radio-checked.svg";
import {designColors} from "~/ui";

import {IIconBase} from "../../_common/types";
import {commonStyles} from "../../_common/styles";
import {simpleIconStyles} from "../styles";

export const BmRadioCheckedIcon: React.FC<IIconBase> = ({id, size = "lg", color = designColors.primary, className}) => {
  return <SvgRadioChecked id={id} className={cx(simpleIconStyles(size), commonStyles.fillColor(color), className)} />;
};
