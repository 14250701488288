import {cx} from "@emotion/css";
import {Stack, Box, useMediaQuery} from "@mui/material";

import {BmPageTitle} from "~/components/titles";
import {LayoutType, PageContentVariant} from "~/layouts/pageContents/types";
import {IBreadcrumbItem} from "~/model/helperTypes/Breadcrumb";
import {BmBreadcrumbs} from "~/components/breadcrumbs";
import {theme} from "~/ui/theme";

import {styles} from "./ContentHeader.styles";

interface ContentHeaderProps extends LayoutType {
  variant: PageContentVariant;
  breadcrumbs?: IBreadcrumbItem[];
}

export const ContentHeader: React.FC<ContentHeaderProps> = ({
  className,
  variant,
  breadcrumbs,
  title,
  subtitle,
  statusLabel,
  headerButtons,
}) => {
  const isGreaterThanMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box component="header" className={cx(styles.root(variant), className)}>
      {!!breadcrumbs && (
        <Box mb={2}>
          <BmBreadcrumbs breadcrumbs={breadcrumbs} />
        </Box>
      )}
      <Box className={styles.headerMainContent}>
        <Stack spacing={2} direction="row" alignItems="center" useFlexGap>
          <BmPageTitle>{title}</BmPageTitle>
          {statusLabel}
        </Stack>

        {headerButtons && (
          <Stack
            direction={isGreaterThanMd ? "row" : "column"}
            spacing={2}
            sx={{marginTop: {xs: "10px", md: 0}}}
            useFlexGap
          >
            {headerButtons}
          </Stack>
        )}
      </Box>

      {subtitle}
    </Box>
  );
};
