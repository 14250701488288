import React from "react";
import {cx} from "@emotion/css";
import {SxProps, Theme} from "@mui/material";
import MuiModal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";

import {TRANSITION_TIME} from "~/constants/constants";

import {styles} from "./BaseModal.styles";
import {IModalContent} from "./contentTemplates/IModalContent";
import {BmBaseModalContent} from "./contentTemplates/BaseModalContent";

export interface BaseModalProps {
  open: boolean;
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
  bodySx?: SxProps<Theme>;
  minWidth?: string;
  maxWidth?: string;
  width?: string;
  closeButton?: boolean;
  disableEscapeKeyDown?: boolean;
  disableBackdropClose?: boolean;
  onClose: VoidFunction;
  title?: React.ReactNode | string;
  children?: React.ReactNode;
  footerButtons?: React.ReactNode;
  ModalContentComponent?: React.ComponentType<IModalContent>;
}

export const BmBaseModal: React.FC<BaseModalProps> = ({
  open,
  className,
  headerClassName,
  bodyClassName,
  bodySx,
  minWidth,
  maxWidth,
  width,
  closeButton = false,
  disableEscapeKeyDown,
  disableBackdropClose,
  onClose,
  title,
  children,
  footerButtons,
  ModalContentComponent = BmBaseModalContent,
}) => {
  return (
    <MuiModal
      open={open}
      disableEscapeKeyDown={disableEscapeKeyDown}
      onClose={(_, reason) => {
        if (disableBackdropClose && reason === "backdropClick") {
          return;
        }
        onClose();
      }}
      className={cx(styles.modalWrap, className)}
    >
      <Fade in={open} timeout={TRANSITION_TIME}>
        <Box
          maxWidth={maxWidth}
          minWidth={minWidth}
          width={width}
          className={cx(styles.modal, bodyClassName)}
          sx={{zIndex: 1, ...bodySx}}
        >
          <ModalContentComponent
            headerClassName={headerClassName}
            closeButton={closeButton}
            onClose={onClose}
            title={title}
            footerButtons={footerButtons}
          >
            {children}
          </ModalContentComponent>
        </Box>
      </Fade>
    </MuiModal>
  );
};
