import {cx} from "@emotion/css";

import {ReactComponent as SvgLocation} from "~/assets/svg/location.svg";
import {designColors} from "~/ui";
import {IIconBase} from "~/components/icons/_common/types";

import {commonStyles} from "../../_common/styles";

interface LocationProps extends Omit<IIconBase, "size"> {}

export const BmLocation: React.FC<LocationProps> = ({id, className, color = designColors.black}) => {
  return <SvgLocation id={id} className={cx(commonStyles.color(color), className)} />;
};
