import {css} from "@emotion/css";

import {theme} from "~/ui/theme";
import {PageContentWidthVariant} from "~/layouts/pageContents/types";
import {designConstants} from "~/ui";

export const styles = {
  root: (contentWidthVariant: PageContentWidthVariant) => css`
    position: relative;
    padding: 24px 12px 72px;
    padding-top: calc(24px + ${designConstants.minHeaderHeight});
    margin: 0 auto;
    width: 100%;
    ${contentWidthVariant === "fitContent" &&
    css`
      max-width: fit-content;
    `}

    ${theme.breakpoints.up("sm")} {
      padding: 24px 24px 72px;
      padding-top: calc(24px + ${designConstants.minHeaderHeight});

      ${{
        fullWidth: css`
          max-width: 100%;
        `,
        oneColumn: css`
          max-width: calc(${designConstants.oneColumnContentWidth} + 48px);
        `,
        twoColumns: css`
          max-width: calc(${designConstants.twoColumnsContentWidth} + 48px);
        `,
        fitContent: css`
          max-width: fit-content;
        `,
      }[contentWidthVariant]}
    }

    ${theme.breakpoints.up("lg")} {
      padding: 24px 48px 72px;

      ${{
        fullWidth: css`
          max-width: 100%;
        `,
        oneColumn: css`
          max-width: calc(${designConstants.oneColumnContentWidth} + 96px);
        `,
        twoColumns: css`
          max-width: calc(${designConstants.twoColumnsContentWidth} + 96px);
        `,
        fitContent: css`
          max-width: fit-content;
        `,
      }[contentWidthVariant]}
    }
  `,
};
