import Box from "@mui/material/Box";

import {Header} from "~/containers/header/Header";
import {designColors} from "~/ui";

export interface BmSimplePageLayoutProps {
  children: React.ReactNode;
  buttonName?: string;
  onButtonClick?: VoidFunction;
}

export const BmSimplePageLayout: React.FC<BmSimplePageLayoutProps> = ({children, buttonName, onButtonClick}) => (
  <Box bgcolor={designColors.off_white} minHeight="100vh" display="flex" flexDirection="column">
    <Header buttonName={buttonName} onButtonClick={onButtonClick} />
    {children}
  </Box>
);
