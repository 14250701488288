import {Breakpoint, useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const useIsMobile = (customMobileBreakpoint: Breakpoint = "md"): boolean[] => {
  const theme = useTheme();
  const isTabletAndLower = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobileAndLower = useMediaQuery(theme.breakpoints.down(customMobileBreakpoint));

  return [isTabletAndLower, isMobileAndLower];
};
