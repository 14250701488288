import MenuItem from "@mui/material/MenuItem";
import {cx} from "@emotion/css";

import {styles} from "./SidebarItem.styles";

type SidebarItemProps = {
  disabled?: boolean;
  selected?: boolean;
  className?: string;
  onClick?: VoidFunction;
  children: React.ReactElement;
};

export const SidebarItem: React.FC<SidebarItemProps> = ({disabled, selected, className, onClick, children}) => {
  return (
    <MenuItem onClick={onClick} className={cx(styles.root, disabled && styles.disabled, className)} selected={selected}>
      {children}
    </MenuItem>
  );
};
