import {css} from "@emotion/css";

import {designColors, designConstants} from "~/ui";

export const styles = {
  header: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: ${designConstants.minHeaderHeight};
    padding: 18px 24px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;

    background-color: ${designColors.brand};
  `,
  logoLink: css`
    margin-left: 14px;
  `,
};
