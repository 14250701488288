import {css} from "@emotion/css";

export const styles = {
  root: css`
    position: relative;
    display: flex;
    justify-content: center;

    &[disabled] {
      &,
      & * {
        cursor: not-allowed;
      }

      & > * {
        opacity: 0.5;
      }
    }
  `,
};
