import Box from "@mui/material/Box";

export interface JustifyContentBetweenProps {
  className?: string;
  children: React.ReactNode;
}

export const JustifyContentBetween: React.FC<JustifyContentBetweenProps> = ({className, children}) => (
  <Box className={className} display="flex" justifyContent="space-between">
    {children}
  </Box>
);
