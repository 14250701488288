import {cx} from "@emotion/css";

import {designColors} from "~/ui";
import {ReactComponent as SvgRemove} from "~/assets/svg/trash.svg";

import {simpleIconStyles} from "../styles";
import {commonStyles} from "../../_common/styles";
import {IIconBase} from "../../_common/types";

export const BmRemoveIcon: React.FC<IIconBase> = ({id, size = "sm", color = designColors.black, className}) => {
  return <SvgRemove id={id} className={cx(simpleIconStyles(size), commonStyles.color(color), className)} />;
};
