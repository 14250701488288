import {css} from "@emotion/css";

import {designTokens, designUtils} from "~/ui";

export const styles = {
  textButton: css`
    &.MuiButton-root {
      ${designUtils.baseFontStyles};
      font-weight: ${designTokens.fontWeight.semiBold};
      text-transform: none;
      &:hover {
        background-color: transparent;
      }
      .MuiTouchRipple-root {
        display: none;
      }
    }
  `,
};
