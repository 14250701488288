import MuiTableRow from "@mui/material/TableRow";

import {BmTableBody} from "~/components/table";

import {BmTable} from "../../table/Table/Table";
import {BmTableCellFull} from "../../table/TableCellFull/TableCellFull";
import {BmTableRow} from "../../table/TableRow/TableRow";
import {BmSkeleton} from "../Skeleton/Skeleton";
import {styles} from "./TableSkeletonMobile.styles";

export interface BmTableSkeletonMobileProps {
  rows?: number;
  cells?: number;
  className?: string;
}

export const BmTableSkeletonMobile: React.FC<BmTableSkeletonMobileProps> = ({rows = 1, cells = 8, className}) => {
  return (
    <BmTable className={className}>
      <BmTableBody>
        <MuiTableRow hover={false}>
          <BmTableCellFull>
            <BmSkeleton height={32} />
          </BmTableCellFull>
        </MuiTableRow>
        {[...Array(rows)].map((row, i) => (
          <BmTableRow key={i} hover={false}>
            {[...Array(cells)].map((cell, i) => (
              <BmTableCellFull key={i}>
                <div className={styles.tableCellMobile}>
                  <BmSkeleton height={20} />
                  <BmSkeleton height={20} />
                </div>
              </BmTableCellFull>
            ))}
          </BmTableRow>
        ))}
      </BmTableBody>
    </BmTable>
  );
};
