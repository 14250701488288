import {createSlice} from "@reduxjs/toolkit";

import {ILocalWebshopProduct} from "~/model/DAO/IWebshop";

import {resetAll} from "./global";

export interface LocalWebshopProductState extends ILocalWebshopProduct {}

const initialState: LocalWebshopProductState = {};

export const LocalWebshopProductSlice = createSlice({
  name: "localWebshopProduct",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setLocalWebshopProduct: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetLocalWebshopProduct: () => initialState,
  },
});

export const LocalWebshopProductActions = LocalWebshopProductSlice.actions;

export default LocalWebshopProductSlice.reducer;
