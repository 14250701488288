import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";

import {styles} from "./SidebarItemBadgeNew.styles";

interface SidebarItemBadgeNewProps {}

export const SidebarItemBadgeNew: React.FC<SidebarItemBadgeNewProps> = () => {
  const {t} = useTranslation();

  return (
    <Box component="span" className={styles.root}>
      {t("labels.new")}
    </Box>
  );
};
