import {Provider} from "react-redux";
import {CookiesProvider} from "react-cookie";
import {BrowserRouter as Router, Switch} from "react-router-dom";
import {CompatRouter} from "react-router-dom-v5-compat";
import Zendesk from "react-zendesk";
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {SnackbarProvider} from "notistack";

import {theme} from "~/ui/theme";
import {store} from "~/modules/redux";
import {buildAppRoutes} from "~/modules/routing";
import {BmToast} from "~/components/toasts/Toast/Toast";

const zendeskKey = process.env.REACT_APP_ZENDESK_API_KEY || "";

const App: React.FC = () => {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <BmToast />
            <Router>
              <CompatRouter>
                <Switch>{buildAppRoutes()}</Switch>
              </CompatRouter>
            </Router>
          </SnackbarProvider>
        </MuiThemeProvider>
        <Zendesk zendeskKey={zendeskKey} />
      </Provider>
    </CookiesProvider>
  );
};

export default App;
