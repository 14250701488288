import {Button as MuiButton} from "@mui/material";
import {cx} from "@emotion/css";

import {IButton} from "../IButton";
import {styles} from "./TextButton.styles";

export interface BmTextButtonProps extends IButton {
  icon?: React.ReactNode;
}

export const BmTextButton: React.FC<BmTextButtonProps> = ({className, sx, onClick, icon, children}) => {
  return (
    <MuiButton
      sx={sx}
      className={cx(styles.textButton, className)}
      color="primary"
      variant="text"
      onClick={onClick}
      startIcon={icon}
    >
      {children}
    </MuiButton>
  );
};
