import MuiTableSortLabel from "@mui/material/TableSortLabel";

import {BmContextMenuWithArrowButton} from "~/components/contextMenu/ContextMenu/facades/ContextMenuWithArrowButton/ContextMenuWithArrowButton";
import {BmContextMenuItem} from "~/components/contextMenu";

import {useBmTableSortingContext} from "./TableSorting.context";

export const BmTableSorting: React.FC = () => {
  const {sortList, sortDirection, sortBy, buttonText, sortToggle} = useBmTableSortingContext();
  return (
    <BmContextMenuWithArrowButton buttonText={buttonText}>
      {sortList.map((el) => (
        <BmContextMenuItem
          key={el.key}
          title={
            <MuiTableSortLabel
              active={sortBy === el.sortField}
              direction={sortBy === el.sortField ? sortDirection! : "asc"}
            >
              {el.label}
            </MuiTableSortLabel>
          }
          onClick={() => {
            sortToggle(el.sortField, el.numeric);
          }}
        />
      ))}
    </BmContextMenuWithArrowButton>
  );
};
