import {cx} from "@emotion/css";
import Box from "@mui/material/Box";
import {SxProps, Theme} from "@mui/material";

import {designColors, designTokens} from "~/ui";

export interface BmPageTitleProps {
  className?: string;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

export const BmPageTitle: React.FC<BmPageTitleProps> = ({className, sx, children}) => {
  return (
    <Box
      component="h1"
      className={cx("pageTitle", className)}
      sx={{
        color: designColors.colorInk,
        fontSize: designTokens.fontSize.xxl,
        fontWeight: designTokens.fontWeight.bold,
        lineHeight: "3rem",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
