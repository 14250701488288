import Box from "@mui/material/Box";

import {styles} from "./SidebarSection.styles";

interface SidebarSectionProps {
  children: React.ReactNode;
  groupTitle?: string;
}

export const SidebarSection: React.FC<SidebarSectionProps> = ({groupTitle, children}) => {
  return (
    <Box>
      {groupTitle && (
        <Box className={styles.groupTitle} pl={2} pb={1.5}>
          {groupTitle}
        </Box>
      )}
      {children}
    </Box>
  );
};
