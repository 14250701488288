import {createContext, useContext} from "react";

import {PageInfo} from "~/api/generated";
import {noop} from "~/utils/noop";

import {PaginationDirectionType} from "./ITablePagination";

interface IBmTablePaginationContext {
  pageInfo: PageInfo;
  rowsPerPage: number;
  isLoading: boolean;
  onPageChange: (direction: PaginationDirectionType, cursor: string) => void;
  onRowsPerPageChange: (nextRowsPerPage: number) => void;
}

const BmTablePaginationContext = createContext<IBmTablePaginationContext>({
  pageInfo: {
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: undefined,
    endCursor: undefined,
  },
  rowsPerPage: 10,
  isLoading: false,
  onPageChange: noop,
  onRowsPerPageChange: noop,
});

export const BmTablePaginationContextProvider = BmTablePaginationContext.Provider;

export const useBmTablePaginationContext = () => useContext(BmTablePaginationContext);
