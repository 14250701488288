import {useTranslation} from "react-i18next";
import RefreshIcon from "@mui/icons-material/Refresh";
import {useHistory} from "react-router-dom";
import {useCallback} from "react";

import {designConstants} from "~/ui";
import {BmBaseInfoBanner} from "~/components/banners";
import {BmPrimaryButton} from "~/components/buttons";

import {useAutomaticRefresh} from "./private/useAutomaticRefresh";

export const RefreshBanner: React.FC = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const isRefreshed = useAutomaticRefresh();

  const refreshHandler = useCallback(() => {
    history.go(0);
  }, [history]);

  if (!isRefreshed) return null;

  return (
    <BmBaseInfoBanner
      colorPreset="navy"
      title={t("refresh_banner.title")}
      description={t("refresh_banner.description")}
      leftColumn={<RefreshIcon />}
      footer={<BmPrimaryButton onClick={refreshHandler}>{t("buttons.refresh")}</BmPrimaryButton>}
      sx={{
        position: "fixed",
        top: {xs: 84, lg: 12},
        left: "50%",
        transform: "translateX(-50%)",
        width: {xs: "90%", sm: "100%"},
        maxWidth: 405,
        height: "max-content",
        boxShadow: designConstants.boxShadow,
        zIndex: 11,
      }}
    />
  );
};
