import {cx} from "@emotion/css";
import {DataGrid as MuiDataGrid} from "@mui/x-data-grid/DataGrid";
import {DataGridProps} from "@mui/x-data-grid/models/props/DataGridProps";

import {styles} from "./DataGrid.styles";
import {useKeepTabIndex} from "./private/useKeepTabIndex";

export const BmDataGrid: React.FC<DataGridProps> = ({
  rows,
  columns,
  getRowHeight,
  getEstimatedRowHeight,
  sx,
  className,
  ...props
}) => {
  useKeepTabIndex();

  return (
    <MuiDataGrid
      {...props}
      rows={rows}
      columns={columns}
      autoHeight
      hideFooter
      hideFooterPagination
      disableColumnMenu
      getRowHeight={getRowHeight}
      getEstimatedRowHeight={getEstimatedRowHeight}
      sx={sx}
      className={cx(styles.dataGrid, className)}
    />
  );
};
