import {isEmpty, isNil} from "ramda";

import {AnyObject} from "~/model/helperTypes/generic";

export const removePropFromNestedObject = (propName: string, obj: AnyObject) => {
  Object.keys(obj).forEach(
    (key) =>
      (key === propName && delete obj[key]) ||
      (obj[key] && typeof obj[key] === "object" && removePropFromNestedObject(propName, obj[key])),
  );
  return obj;
};

export const removeNilFromNestedObject = (obj: AnyObject) => {
  Object.keys(obj).forEach(
    (key) =>
      (isNil(obj[key]) && delete obj[key]) ||
      (obj[key] && typeof obj[key] === "object" && removeNilFromNestedObject(obj[key])),
  );
  return obj;
};

// pay attention that it removes only empy Objects and Arrays!
// primitives are not touched
export const removeEmptyFromNestedObject = (obj: AnyObject) => {
  Object.keys(obj).forEach(
    (key) =>
      (isEmpty(obj[key]) && delete obj[key]) ||
      (obj[key] && typeof obj[key] === "object" && removeEmptyFromNestedObject(obj[key])),
  );
  return obj;
};
