/* eslint-disable no-console */
const backup = console.error;
const supressedWarnings = [
  "`@import` rules must be before all other types of rules in a stylesheet",
  "React.Fragment can only have `key` and `children` props.",
];

console.error = function filterWarnings(msg) {
  if (typeof msg === "string") {
    if (supressedWarnings.some((entry) => msg.includes(entry))) {
      return;
    }
  }
  backup.apply(console, arguments);
};
