import * as History from "history";
import {NavLink as RouterLink} from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

import {styles} from "./SidebarLink.styles";

interface SidebarLinkProps {
  disabled?: boolean;
  tooltipTitle: string;
  to: string | ((location: History.Location) => string);
  children: React.ReactElement;
}

export const SidebarLink: React.FC<SidebarLinkProps> = ({disabled, tooltipTitle, to, children}) => {
  return (
    <Tooltip title={disabled ? tooltipTitle : ""}>
      <RouterLink
        to={disabled ? "#" : to}
        className={styles.root}
        activeClassName={disabled ? undefined : "link-active"}
        exact
      >
        {children}
      </RouterLink>
    </Tooltip>
  );
};
