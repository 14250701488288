import {cx} from "@emotion/css";
import {useMemo} from "react";

import {designColors} from "~/ui";

import {simpleIconStyles} from "../../styles";
import {IIconBase} from "../../../_common/types";
import {BmChevronDownIcon} from "./ChevronDown";
import {BmChevronUpIcon} from "./ChevronUp";

interface BmChevronProps extends IIconBase {
  direction?: "down" | "up";
}

export const BmChevronIcon: React.FC<BmChevronProps> = ({
  id,
  size = "lg",
  direction = "down",
  color = designColors.black,
  className,
}) => {
  const Chevron = useMemo(() => (direction === "down" ? BmChevronDownIcon : BmChevronUpIcon), [direction]);
  return <Chevron id={id} className={cx(simpleIconStyles(size), className)} color={color} />;
};
