import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const manifestApi = createApi({
  reducerPath: "manifestApi",
  baseQuery: fetchBaseQuery(),
  endpoints: (build) => ({
    getLastModifiedParam: build.query<any, void>({
      query: () => ({
        url: "/manifest.json",
        method: "GET",
      }),
      transformResponse: async (response, meta) => {
        const lastModified = meta?.response?.headers.get("Last-Modified");

        const data = await response;
        // Return an object containing the parsed response data and the last-modified value
        return {data, lastModified};
      },
    }),
  }),
});

export const {useLazyGetLastModifiedParamQuery} = manifestApi;
