import {cx} from "@emotion/css";
import React from "react";

import {BmUnstyledBaseButton, UnstyledBaseButtonProps} from "..";
import {styles} from "./UnstyledSvgButton.styles";

export interface UnstyledSvgButtonProps extends UnstyledBaseButtonProps {
  disabled?: boolean;
}

export const BmUnstyledSvgButton = React.forwardRef<HTMLButtonElement, UnstyledSvgButtonProps>(
  function UnstyledSvgButton({disabled, className, ...restProps}, ref) {
    return (
      <BmUnstyledBaseButton
        {...restProps}
        ref={ref}
        className={cx("svgBtn", styles.root, className)}
        disabled={disabled}
      />
    );
  },
);
