import {useEffect} from "react";
import {UseFormSetError} from "react-hook-form";

import {FieldErrorType} from "~/model/helperTypes/Errors";
import {AnyObject} from "~/model/helperTypes/generic";
import {onBEFormValidationErrorCallback} from "~/utils/form";

export const useFormError = <T extends AnyObject>(
  backendErrors: FieldErrorType[] | undefined | null,
  setError: UseFormSetError<T>,
  fieldList?: string[],
) => {
  useEffect(() => {
    if (!backendErrors?.length) return;
    backendErrors.forEach(({field, message, type}) => {
      if (fieldList?.length) {
        if (!fieldList.includes(field)) return;
      }
      setError(field, {type: type || "server", message});
      onBEFormValidationErrorCallback(backendErrors);
    });
  }, [backendErrors, fieldList, setError]);
};
