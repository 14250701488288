import {css} from "@emotion/css";

import {designUtils} from "~/ui";
import {theme} from "~/ui/theme";

export const styles = {
  root: css`
    &.MuiMenuItem-root {
      min-height: auto;
      padding: 0;
      transition: ${designUtils.transition()};
      margin-bottom: 2px;

      &.Mui-selected {
        background: none;
      }
      &:hover,
      &.Mui-selected:hover,
      &.Mui-selected a > div {
        background-color: ${theme.palette.brand.light};
        border-radius: 2px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  `,
  disabled: css`
    opacity: 0.4;
    cursor: default;

    &.MuiMenuItem-root {
      &:hover {
        background-color: transparent;
      }
    }
    a {
      background-color: transparent !important;
    }
  `,
};
