import Grid from "@mui/material/Grid";

import {BmLabel} from "~/components/labels";

export interface BmBasicsInfoListItemProps {
  gridSize?: number;
  label: string;
  content: React.ReactNode;
}

export const BmBasicsInfoListItem: React.FC<BmBasicsInfoListItemProps> = ({gridSize, label, content}) => {
  return (
    <Grid item xs={12} md={gridSize || 6}>
      <BmLabel variant="semiBold" sx={{mb: 1}}>
        {label}
      </BmLabel>
      {typeof content === "number" && <div dangerouslySetInnerHTML={{__html: `${content}`}} />}
      {typeof content === "string" && <div dangerouslySetInnerHTML={{__html: content}} />}
      {typeof content === "object" && content !== null && <div>{content}</div>}
    </Grid>
  );
};
