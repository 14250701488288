import {Link as MuiLink} from "@mui/material";
import {cx} from "@emotion/css";

import {ILink} from "../ILink";
import {styles} from "./SecondaryLink.styles";

export const BmSecondaryLink: React.FC<ILink> = ({
  href,
  target,
  underline = "always",
  rel,
  className,
  sx,
  onClick,
  children,
}) => {
  return (
    <MuiLink
      className={cx(styles.link, className)}
      sx={sx}
      href={href}
      underline={underline}
      onClick={onClick}
      target={target}
      rel={rel}
    >
      {children}
    </MuiLink>
  );
};
