import IconButton from "@mui/material/IconButton";

import {BmCloseIcon, BmInfoIcon} from "~/components/icons/simpleIcons";
import {designColors} from "~/ui";
import {BmBaseInfoBanner, BmBaseInfoBannerProps} from "~/components/banners";

export interface BmInfoNotificationBannerProps
  extends Omit<BmBaseInfoBannerProps, "bodySx" | "colorPreset" | "leftColumn" | "rightColumn"> {
  closeButton?: boolean;
  onClose?: VoidFunction;
}

export const BmInfoNotificationBanner: React.FC<BmInfoNotificationBannerProps> = ({
  closeButton,
  onClose,
  ...restProps
}) => {
  return (
    <BmBaseInfoBanner
      {...restProps}
      bodySx={{flexWrap: "nowrap"}}
      colorPreset="navy"
      leftColumn={<BmInfoIcon id="info-icon" />}
      rightColumn={
        closeButton ? (
          <IconButton onClick={onClose} disableRipple size="small">
            <BmCloseIcon id="close-icon" color={designColors.colorInk} />
          </IconButton>
        ) : undefined
      }
    />
  );
};
