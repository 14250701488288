import {useCallback, useEffect, useState} from "react";

import {uniqueIdGenerator} from "~/utils/uniqueIdGenerator";

export const useForceReloadOnUpdate = (dependencies: ReadonlyArray<any> = []): [string, VoidFunction] => {
  const [componentKey, setNewKey] = useState(uniqueIdGenerator());

  const generateNewKey = useCallback(() => setNewKey(uniqueIdGenerator()), []);
  // eslint-disable-next-line
  useEffect(() => generateNewKey(), dependencies);

  return [componentKey, generateNewKey];
};
