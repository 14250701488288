import {css} from "@emotion/css";
import {rgba} from "polished";

import {designColors, designTokens} from "~/ui";

export const styles = {
  groupTitle: css`
    color: ${rgba(designColors.white, 0.6)};
    font-size: ${designTokens.fontSize.xs};
    font-weight: ${designTokens.fontWeight.semiBold};
    text-transform: uppercase;
    letter-spacing: 1px;
  `,
};
