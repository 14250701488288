import {css} from "@emotion/css";

import {designTokens, designUtils} from "~/ui";

export const styles = {
  arrowPrimaryButton: css`
    &.MuiLink-button {
      ${designUtils.baseFontStyles};
      font-weight: ${designTokens.fontWeight.semiBold};
    }
  `,
  icon: css`
    margin-left: 6px;
  `,
};
