import React from "react";
import MuiTableRow, {TableRowProps} from "@mui/material/TableRow";
import {cx} from "@emotion/css";

import {styles} from "./TableRow.styles";
import {useBmTableSettingsContext} from "../TableSettings.context";

interface BmTableRowProps extends TableRowProps {
  hover?: boolean;
  draggable?: boolean;
  active?: boolean;
}

export const BmTableRow = React.forwardRef<HTMLTableRowElement, BmTableRowProps>(function BmTableRow(
  {children, onClick, hover = true, draggable = false, active = false, className, ...restProps},
  ref,
) {
  const {newMobileDesign} = useBmTableSettingsContext();
  return (
    <MuiTableRow
      {...restProps}
      ref={ref}
      className={cx(styles.tableRow(newMobileDesign, draggable, active), className)}
      hover={hover}
      onClick={onClick}
    >
      {children}
    </MuiTableRow>
  );
});
