import Stack from "@mui/material/Stack";
import MuiButton from "@mui/material/Button";
import {cx} from "@emotion/css";
import Box from "@mui/material/Box";

import {BmRemoveIcon} from "~/components/icons/simpleIcons";
import {designColors} from "~/ui";

import {IButton} from "../IButton";
import {styles} from "./DeleteButton.styles";
import {commonStyles} from "../_common/styles";

export const BmDeleteButton: React.FC<IButton> = ({className, sx, onClick, children}) => {
  return (
    <MuiButton
      color="error"
      variant="text"
      onClick={onClick}
      className={cx(commonStyles.button, styles.deleteButton, className)}
      sx={sx}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <BmRemoveIcon id={`delete-icon`} color={designColors.red_3} />
        <Box>{children}</Box>
      </Stack>
    </MuiButton>
  );
};
