import Box from "@mui/material/Box";

import {styles} from "./TermsAndConditionsPageContent.styles";

export interface BmTermsAndConditionsPageContentProps {
  children: React.ReactNode;
}

export const BmTermsAndConditionsPageContent: React.FC<BmTermsAndConditionsPageContentProps> = ({children}) => {
  return <Box className={styles.root}>{children}</Box>;
};
