import {useState, useRef, useEffect} from "react";

interface ReturnObject {
  start: () => void;
  stop: () => void;
  toggle: () => void;
  active: boolean;
}

export const useInterval = (fn: VoidFunction, interval: number): ReturnObject => {
  const [active, setActive] = useState(false);
  const intervalRef = useRef<number>();
  const fnRef = useRef<VoidFunction>();
  useEffect(() => {
    fnRef.current = fn;
  }, [fn]);
  const start = () => {
    setActive((old) => {
      if (!old && !intervalRef.current) {
        intervalRef.current = window.setInterval(fnRef.current!, interval);
      }
      return true;
    });
  };
  const stop = () => {
    setActive(false);
    window.clearInterval(intervalRef.current);
    intervalRef.current = void 0;
  };
  const toggle = () => {
    if (active) {
      stop();
    } else {
      start();
    }
  };
  return {start, stop, toggle, active};
};
