import {css} from "@emotion/css";

export const styles = {
  tableCellMobile: css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    span {
      width: 48% !important;
    }
  `,
};
