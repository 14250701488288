export enum KnownErrorCodesEnum {
  Unauthenticated = "UNAUTHENTICATED",
}

export type FieldErrorType = {
  field: any;
  message: string;
  type?: string;
};

export type RESTFieldErrorType = {
  message: string;
};

export type BEValidationErrorType = {
  response: {
    data: any;
    errors: {
      message: string;
      extensions: {
        code: string;
        fields: FieldErrorType[];
      };
      // not useful
      locations: {
        line: number;
        column: number;
      }[];
      path: string[]; // name of mutation
    }[];
  };
};

export type BEAuthenticationErrorType = {
  data: {
    errors: {
      message: string;
      path: string; // name of field with error
    }[];
  };
  status: number;
};

export type BERegularErrorType = {
  response: {
    data: any;
    errors: {
      message: string;
      extensions: {
        code: string;
      };
    }[];
  };
};

export type BERegularObjectErrorType = {
  response: {
    data: any;
    errors: {
      message: string;
      extensions: {
        problems: {
          explanation: string;
          path: string[];
        }[];
      };
      locations: {
        column: number;
        line: number;
      }[];
    }[];
  };
};

export type RESTErrorType = {
  data: {
    errors: {
      message: string;
    }[];
  };
  status: number;
};

export type DefaultError = {
  name: string;
  message: string;
  stack: string;
};
