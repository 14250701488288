import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import {NavLink} from "react-router-dom";

import {IBreadcrumbItem} from "~/model/helperTypes/Breadcrumb";

import {styles} from "./Breadcrumbs.styles";

export interface BmBreadcrumbsProps {
  breadcrumbs: IBreadcrumbItem[];
}

export const BmBreadcrumbs: React.FC<BmBreadcrumbsProps> = ({breadcrumbs}) => {
  return (
    <MuiBreadcrumbs separator="›" aria-label="breadcrumb" className={styles.breadcrumbs}>
      {breadcrumbs.map((breadcrumb, index) =>
        breadcrumb.to ? (
          <NavLink key={index} to={breadcrumb.to} activeClassName={styles.active} className={styles.breadcrumb} exact>
            {breadcrumb.title}
          </NavLink>
        ) : (
          <span key={index} className={styles.breadcrumb}>
            {breadcrumb.title}
          </span>
        ),
      )}
    </MuiBreadcrumbs>
  );
};
