export type SpacingSize = "md" | "lg";

export const getPaddingBySpacingSize = (spacingSize: SpacingSize) => {
  switch (spacingSize) {
    case "lg":
      return 4;
    case "md":
    default:
      return 3;
  }
};
