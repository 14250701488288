import {useEffect} from "react";

// taken from https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
export const useCombinedRefs = <T extends HTMLElement>(
  innerRef: React.MutableRefObject<T | null>,
  fwdRef: React.ForwardedRef<T>,
) => {
  useEffect(() => {
    [innerRef, fwdRef].forEach((ref) => {
      if (!ref) return;
      if (typeof ref === "function") {
        ref(innerRef.current || null);
      } else {
        ref.current = innerRef.current || null;
      }
    });
  }, [innerRef, fwdRef]);
  return innerRef;
};
