import React from "react";
import {SxProps, Theme} from "@mui/material/styles";
import MuiTableCell from "@mui/material/TableCell";
import {SortDirection, TableCellProps} from "@mui/material/TableCell/TableCell";
import {cx} from "@emotion/css";

import {styles} from "./TableCellDouble.styles";
import {useBmTableSettingsContext} from "../TableSettings.context";

interface BmTableCellProps extends TableCellProps {
  sx?: SxProps<Theme>;
  rightAlign?: boolean;
  optionsCell?: boolean;
  sortDirection?: SortDirection;
  colSpan?: number;
  rowSpan?: number;
  onClick?: (value: any) => void;
  label?: string;
  underLabel?: string;
  children?: React.ReactNode;
}

export const BmTableCellDouble = React.forwardRef<HTMLTableRowElement, BmTableCellProps>(function BmTableCell(
  {
    sx,
    className,
    rightAlign,
    optionsCell,
    sortDirection,
    colSpan,
    rowSpan,
    onClick,
    label,
    underLabel,
    children,
    ...restProps
  },
  ref,
) {
  const {newMobileDesign} = useBmTableSettingsContext();
  return (
    <MuiTableCell
      {...restProps}
      ref={ref}
      sx={sx}
      className={cx(styles.tableCell(newMobileDesign, rightAlign, optionsCell), className)}
      sortDirection={sortDirection}
      colSpan={colSpan}
      rowSpan={rowSpan}
      onClick={onClick}
    >
      <span>
        <span className={styles.label}>{optionsCell ? children : label}</span>
        {underLabel && <span className={styles.underLabel}>{underLabel}</span>}
      </span>
      <span className={styles.text(optionsCell)}>{children}</span>
    </MuiTableCell>
  );
});
