import {useCallback, useMemo} from "react";

import {useFixedSearchParams} from "~/hooks";

import {ITableSortingCallbacks, ITableSortingParams, SortDirectionType} from "./ITableSorting";

type UseBackendTableSortingFromSearchParamsReturnTuple = [ITableSortingParams, ITableSortingCallbacks];

const sortDirectionProtector = (sortDirectionParam: string): SortDirectionType => {
  return sortDirectionParam.toLowerCase() === "desc" ? "desc" : "asc";
};

export const useBackendTableSortingFromSearchParams = (): UseBackendTableSortingFromSearchParamsReturnTuple => {
  const [searchParams, setSearchParams] = useFixedSearchParams();
  const sortDirectionParam = searchParams.get("sortDirection");
  const sortByParam = searchParams.get("sortBy");

  const sortingParams = useMemo(
    () => ({
      sortDirection: sortDirectionParam ? sortDirectionProtector(sortDirectionParam) : undefined,
      sortBy: sortByParam || undefined,
    }),
    [sortByParam, sortDirectionParam],
  );

  const sortToggle = useCallback(
    (nextSortBy: string) => {
      const {sortDirection, sortBy} = sortingParams;
      const isSameField = sortBy === nextSortBy;
      const isAsc = isSameField && sortDirection === "asc";
      const nextSortDirection = isAsc ? "desc" : "asc";

      setSearchParams((prevSearchParams) => [
        ...[...prevSearchParams].filter(
          ([key]) => !["startCursor", "endCursor", "sortDirection", "sortBy"].includes(key),
        ),
        ["sortDirection", nextSortDirection],
        ["sortBy", nextSortBy],
      ]);
    },
    [setSearchParams, sortingParams],
  );

  return [sortingParams, {sortToggle}];
};
