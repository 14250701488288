import {css} from "@emotion/css";

import {theme} from "~/ui/theme";

export const styles = {
  container: css`
    display: flex;
    min-height: 550px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${theme.breakpoints.down("sm")} {
      min-height: 350px;
    }
  `,
  content: css`
    position: relative;
    width: 658px;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 429px;
    ${theme.breakpoints.down("sm")} {
      min-height: auto;
      width: 100%;
    }
  `,
  image: css`
    position: absolute;
    top: -55px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    ${theme.breakpoints.down("sm")} {
      position: static;
      transform: none;
      margin-bottom: 24px;
    }
  `,
  bg: css`
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    ${theme.breakpoints.down("sm")} {
      display: none;
    }
  `,
  text: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  `,
};
