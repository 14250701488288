import {equals, pick} from "ramda";
import {useEffect, useRef} from "react";
import {FieldErrorsImpl, FieldValues, Path, UseFormTrigger} from "react-hook-form";

import {onFormValidationErrorCallback} from "~/utils/form";

// This hook was created because the page did not scroll to the cnCode field
// when a validation error appeared in it upon clicking the Next button
export const useStepFormError = <TFieldValues extends FieldValues>(
  errors: FieldErrorsImpl<TFieldValues>,
  formData: TFieldValues,
  prevFormData: TFieldValues | undefined,
  trigger: UseFormTrigger<TFieldValues>,
) => {
  const prevErrorsRef = useRef(errors);

  useEffect(() => {
    const fieldsWithError = Object.keys(errors) as Path<TFieldValues>[];
    if (!fieldsWithError.length) return;
    if (!equals(errors, prevErrorsRef.current)) {
      onFormValidationErrorCallback(errors);
      prevErrorsRef.current = errors;
    }

    if (equals(pick(fieldsWithError, formData), pick(fieldsWithError, prevFormData))) return;
    trigger(fieldsWithError);
  }, [errors, formData, prevFormData, trigger]);
};
