import Grid from "@mui/material/Grid";

import {BmBasicsInfoListItem, BmBasicsInfoListItemProps} from "./BasicsInfoListItem";

export interface BmBasicsInfoListProps {
  list: BmBasicsInfoListItemProps[];
}

export const BmBasicsInfoList: React.FC<BmBasicsInfoListProps> = ({list}) => {
  return (
    <Grid container spacing={{xs: 2, md: 4}}>
      {list.map((el, index) => (
        <BmBasicsInfoListItem key={`${el.label}-${index}`} {...el} />
      ))}
    </Grid>
  );
};
