import React, {useMemo} from "react";

import {useAppSelector, strongSelectMaker} from "~/modules/redux";

import {createAbilitiesFor} from "./abilities";
import {AbilityProvider} from "./Can";

export const withAbilities =
  <P,>(Component: React.ComponentType<P>): React.FC<P> =>
  (props) => {
    const maker = useAppSelector(strongSelectMaker);
    const abilities = useMemo(() => createAbilitiesFor(maker), [maker]);

    return (
      <AbilityProvider value={abilities}>
        <Component {...props} />
      </AbilityProvider>
    );
  };
