import {equals} from "ramda";
import {useCallback, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {ITableSortingCallbacks, ITableSortingParams, SortDirectionType} from "./ITableSorting";

type UseBackendTableSortingFromStateReturnTuple = [ITableSortingParams, ITableSortingCallbacks];

type SortingStateParams = {
  sortDirection: SortDirectionType | undefined;
  sortBy: string | undefined;
};

const initSortingStateParams: SortingStateParams = {
  sortDirection: undefined,
  sortBy: undefined,
};

export const useBackendTableSortingFromState = (): UseBackendTableSortingFromStateReturnTuple => {
  const history = useHistory();
  const location = useLocation();
  const [sortingParams, setSortingStateParams] = useState<SortingStateParams>(initSortingStateParams);

  const sortToggle = useCallback(
    (nextSortBy: string) => {
      const {sortBy, sortDirection} = sortingParams;
      const isSameField = sortBy === nextSortBy;
      const isAsc = isSameField && sortDirection === "asc";
      const nextSortDirection = isAsc ? "desc" : "asc";
      setSortingStateParams({sortDirection: nextSortDirection, sortBy: nextSortBy});
    },
    [sortingParams],
  );

  useEffect(() => {
    const initPathname = location.pathname;
    const initSearch = location.search;

    const unlisten = history.listen(({pathname, search}: any) => {
      if (equals(initPathname, pathname) && !equals(initSearch, search)) return;
      setSortingStateParams(initSortingStateParams);
    });
    return () => unlisten();
  }, [history, location.pathname, location.search]);

  return [sortingParams, {sortToggle}];
};
