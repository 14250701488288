import {css} from "@emotion/css";

import {designTokens} from "./designTokens";

export const designUtils = {
  transition: (speed: number = 0.3) => `all ${speed}s ease`,
  baseFontStyles: css`
    font-size: ${designTokens.fontSize.base};
    line-height: ${designTokens.lineHeight.normal};
  `,
  textOverflow: css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
};
