import {css} from "@emotion/css";

import {designColors} from "~/ui";
import {getHoverColor} from "~/utils/colors";

export const styles = {
  secondaryButton: css`
    &.MuiButton-root {
      border: 1px solid transparent;

      &:hover {
        background-color: ${getHoverColor(designColors.white, "darker")};
        border: 1px solid ${designColors.grey_1};
      }
    }
  `,
};
