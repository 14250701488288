import {css} from "@emotion/css";

import {designColors} from "~/ui";

export const styles = {
  deleteButton: css`
    &.MuiButton-root {
      border: 1px solid transparent;

      color: ${designColors.red_3};
    }
  `,
};
