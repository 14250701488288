import {useCallback, useEffect, useRef, useState} from "react";

export const useLoadingTimeout = (delay: number = 300): [boolean, () => void, () => void] => {
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = useCallback(() => {
    setIsLoading(true);
    timerRef.current = setTimeout(() => setIsLoading(false), delay);
  }, [delay, timerRef]);

  const clearLoading = useCallback(() => {
    setIsLoading(false);
    timerRef?.current && clearTimeout(timerRef.current);
    timerRef.current = undefined;
  }, [timerRef]);

  useEffect(() => {
    return () => clearLoading();
  }, [clearLoading]);

  return [isLoading, startLoading, clearLoading];
};
