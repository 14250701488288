import {cx} from "@emotion/css";

import {ReactComponent as SvgMenu} from "~/assets/svg/menu.svg";
import {designColors} from "~/ui";

import {simpleIconStyles} from "../styles";
import {commonStyles} from "../../_common/styles";
import {IIconBase} from "../../_common/types";

export const BmMenuIcon: React.FC<IIconBase> = ({id, size = "lg", color = designColors.black, className}) => {
  return <SvgMenu id={id} className={cx(simpleIconStyles(size), commonStyles.color(color), className)} />;
};
