import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {cx} from "@emotion/css";

import {BmUnstyledSvgButton} from "~/components/buttons";
import {BmCloseIcon} from "~/components/icons/simpleIcons";
import {BmCommonTitleSmall} from "~/components/titles";

import {styles} from "./styles";
import {IModalContent} from "../IModalContent";

export const BmPickingListModalContent: React.FC<IModalContent> = ({
  headerClassName,
  closeButton,
  onClose,
  title,
  children,
}) => {
  return (
    <Box p="24px">
      <Stack spacing="24px">
        {(title || closeButton) && (
          <Box component="header" className={cx(styles.header, headerClassName)}>
            {title && <BmCommonTitleSmall>{title}</BmCommonTitleSmall>}
            {closeButton && (
              <BmUnstyledSvgButton id="close-modal-button" onClick={onClose}>
                <BmCloseIcon id="close-modal-icon" />
              </BmUnstyledSvgButton>
            )}
          </Box>
        )}
        <Box>{children}</Box>
      </Stack>
    </Box>
  );
};
