import {createContext, useContext} from "react";

interface ITableSettings {
  newMobileDesign: boolean;
}

const BmTableSettingsContext = createContext<ITableSettings>({
  newMobileDesign: false,
});

export const BmTableSettingsContextProvider = BmTableSettingsContext.Provider;

export const useBmTableSettingsContext = () => useContext(BmTableSettingsContext);
