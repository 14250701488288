import MuiLink from "@mui/material/Link";
import {cx} from "@emotion/css";

import {ILink} from "../ILink";
import {styles} from "./PrimaryLink.styles";

export const BmPrimaryLink: React.FC<ILink> = ({
  href,
  target,
  underline = "always",
  weight = "regular",
  rel,
  className,
  sx,
  onClick,
  children,
}) => {
  return (
    <MuiLink
      className={cx(styles.link(!!onClick), styles.weight(weight), className)}
      sx={sx}
      color="primary"
      href={href}
      underline={underline}
      onClick={onClick}
      target={target}
      rel={rel}
    >
      {children}
    </MuiLink>
  );
};
