import MuiTableHead from "@mui/material/TableHead";
import MuiTableSortLabel from "@mui/material/TableSortLabel";
import MuiTableRow from "@mui/material/TableRow";
import {isNil} from "ramda";

import {ColumnNameType} from "../ITable";
import {styles} from "./TableHead.styles";
import {BmTableCell} from "../TableCell/TableCell";
import {useBmTableSortingContext} from "../TableSorting/TableSorting.context";

interface BmTableHeadProps {
  startColumn?: React.ReactNode;
  columnNames: ColumnNameType[];
}

export const BmTableHead: React.FC<BmTableHeadProps> = ({startColumn, columnNames}) => {
  const {sortDirection, sortBy, sortToggle} = useBmTableSortingContext();
  return (
    <MuiTableHead>
      <MuiTableRow className={styles.tableHead}>
        {startColumn}
        {columnNames.map((columnName) => {
          let rightAlign: boolean = false;
          if (!isNil(columnName.numeric)) rightAlign = columnName.numeric;
          if (!isNil(columnName.rightAlign)) rightAlign = columnName.rightAlign;

          return (
            <BmTableCell
              key={columnName.key}
              sortDirection={sortBy === columnName.sortField ? sortDirection! : false}
              rightAlign={rightAlign}
            >
              {columnName.sortable ? (
                <MuiTableSortLabel
                  active={sortBy === columnName.sortField}
                  direction={sortBy === columnName.sortField ? sortDirection! : "asc"}
                  onClick={() => sortToggle(columnName.sortField, columnName.numeric)}
                >
                  {columnName.label}
                </MuiTableSortLabel>
              ) : (
                columnName.label
              )}
            </BmTableCell>
          );
        })}
      </MuiTableRow>
    </MuiTableHead>
  );
};
