import Box from "@mui/material/Box";

import {BmLayoutSkeleton} from "~/components/skeleton";

import {styles} from "./SimplePageContent.styles";

export interface BmSimplePageContentProps {
  loading?: boolean;
  children: React.ReactNode;
}

export const BmSimplePageContent: React.FC<BmSimplePageContentProps> = ({loading, children}) => {
  return (
    <Box className={styles.root}>
      {loading ? (
        <Box sx={{width: "100%", maxWidth: "600px"}}>
          <BmLayoutSkeleton />
        </Box>
      ) : (
        children
      )}
    </Box>
  );
};
