import {Location} from "history";
import {useEffect, useState} from "react";
import {Prompt, useHistory} from "react-router-dom";

import {useOpenClose} from "~/hooks";
import {BmDialogModal} from "~/components/modals";

import {LOGIN_PATH} from "../paths";

type RouteLivingDialogProps = {
  when?: boolean | undefined;
  confirmButtonText: string;
  title?: string;
  content?: string;
};

export const RouteLeavingDialog: React.FC<RouteLivingDialogProps> = ({when, title, content, confirmButtonText}) => {
  const [isOpenModal, openModal, closeModal] = useOpenClose(false);
  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState<boolean>(false);
  const history = useHistory<{from?: string; search?: string}>();

  useEffect(() => {
    if (!confirmedNavigation) return;
    if (!nextLocation) return;

    history.push({
      pathname: nextLocation.pathname,
      search: nextLocation.pathname === history.location.state?.from ? history.location.state?.search : undefined,
    });
  }, [confirmedNavigation, nextLocation, history]);

  const handleBlockedNavigation = (location: Location) => {
    if (confirmedNavigation) return true;
    if (location.pathname === LOGIN_PATH) return true;
    if (location.hash) return true;

    openModal();
    setNextLocation(location);
    return false;
  };

  const handleConfirmNavigationClick = () => {
    closeModal();
    setConfirmedNavigation(true);
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <BmDialogModal
        open={isOpenModal}
        onClose={closeModal}
        onConfirmButtonClick={handleConfirmNavigationClick}
        title={title}
        content={content}
        confirmButtonText={confirmButtonText}
      />
    </>
  );
};
