import React from "react";
import MuiIconButton from "@mui/material/IconButton";

import {IContextMenu} from "../../../IContextMenu";
import {BmContextMenu} from "../../ContextMenu";
import {styles} from "./ContextMenuWithIconButton.styles";

export const BmContextMenuWithIconButton: React.FC<Omit<IContextMenu, "buttonText">> = ({
  className,
  isLoading,
  onClick,
  onClose,
  icon,
  children,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onClick?.();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    onClose?.();
    setAnchorEl(null);
  };

  return (
    <>
      <MuiIconButton size="medium" onClick={handleClick} className={styles.contextMenuButton}>
        {icon}
      </MuiIconButton>
      <BmContextMenu
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        isLoading={isLoading}
        className={className}
      >
        {React.Children.map(children, (child) => {
          if (!child) return;
          return child;
        })}
      </BmContextMenu>
    </>
  );
};
