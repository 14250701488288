import MuiLink from "@mui/material/Link";

import {BmChevronIcon} from "~/components/icons/simpleIcons";
import {designColors} from "~/ui";

import {IButton} from "../IButton";
import {styles} from "./ArrowPrimaryButton.styles";

export interface BmArrowPrimaryButtonProps extends IButton {
  isOpen?: boolean;
}

export const BmArrowPrimaryButton: React.FC<BmArrowPrimaryButtonProps> = ({sx, isOpen, onClick, children}) => {
  return (
    <MuiLink
      color="primary"
      component="button"
      onClick={(e) => {
        // this component somehow causes a form submit
        // so I call preventDefault in order to fix this behaviour
        e.preventDefault();
        onClick?.(e);
      }}
      underline="none"
      className={styles.arrowPrimaryButton}
      sx={{whiteSpace: "nowrap", ...sx}}
    >
      {children}
      <BmChevronIcon
        id="arrow-icon-for-button"
        className={styles.icon}
        size="xs"
        direction={isOpen ? "up" : "down"}
        color={designColors.primary}
      />
    </MuiLink>
  );
};
