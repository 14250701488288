import {cx} from "@emotion/css";

import invoicePreviewSrc from "~/assets/img/invoice-preview.png";
import {IIconBase} from "~/components/icons/_common/types";

import {styles} from "./styles";

interface InvoicePreviewProps extends Omit<IIconBase, "size"> {}

export const BmInvoicePreviewImage: React.FC<InvoicePreviewProps> = ({id, className}) => {
  return <img src={invoicePreviewSrc} id={id} alt="Invoice preview" className={cx(styles.root, className)} />;
};
