import {css} from "@emotion/css";

import {designColors, designTokens} from "~/ui";

export const styles = {
  contextMenu: css`
    .MuiPaper-root {
      padding: 6px 0;
      min-width: 160px;
      min-height: 36px;
    }
    .MuiList-root {
      padding: 0;
    }
  `,
  loadingState: css`
    &.MuiMenuItem-root {
      color: ${designColors.grey_5};
      font-size: ${designTokens.fontSize.sm};
    }
  `,
};
