import MuiLink from "@mui/material/Link";
import {Link} from "react-router-dom";
import {cx} from "@emotion/css";

import {designColors} from "~/ui";

import {ILink} from "../ILink";
import {styles} from "./SecondaryRouterLink.styles";

interface RouterLinkProps extends ILink {
  to: string;
}

export const BmSecondaryRouterLink: React.FC<RouterLinkProps> = ({
  to,
  underline = "always",
  className,
  sx,
  color = designColors.colorInk,
  children,
}) => {
  return (
    <MuiLink className={cx(styles.link(color), className)} sx={sx} component={Link} to={to} underline={underline}>
      {children}
    </MuiLink>
  );
};
