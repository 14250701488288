import {cx} from "@emotion/css";

import {ReactComponent as SvgRadioEmpty} from "~/assets/svg/radio-empty.svg";
import {designColors} from "~/ui";

import {IIconBase} from "../../_common/types";
import {commonStyles} from "../../_common/styles";
import {simpleIconStyles} from "../styles";

export const BmRadioEmptyIcon: React.FC<IIconBase> = ({id, size = "lg", color = designColors.grey_4, className}) => {
  return <SvgRadioEmpty id={id} className={cx(simpleIconStyles(size), commonStyles.strokeColor(color), className)} />;
};
