import Box from "@mui/material/Box";
import {cx} from "@emotion/css";

import {BmUnstyledSvgButton} from "~/components/buttons";
import {BmCloseIcon} from "~/components/icons/simpleIcons";
import {BmCommonTitleSmall} from "~/components/titles";

import {styles} from "./styles";
import {IModalContent} from "../IModalContent";

export const BmBaseModalContent: React.FC<IModalContent> = ({
  headerClassName,
  closeButton,
  onClose,
  title,
  children,
}) => {
  return (
    <Box>
      <Box component="header" className={cx(styles.header, headerClassName)}>
        <BmCommonTitleSmall>{title}</BmCommonTitleSmall>
        {closeButton && (
          <BmUnstyledSvgButton id="close-modal-button" onClick={onClose}>
            <BmCloseIcon id="close-modal-icon" />
          </BmUnstyledSvgButton>
        )}
      </Box>
      <Box padding={4}>{children}</Box>
    </Box>
  );
};
