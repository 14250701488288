import {useEffect} from "react";

export const useInsertCssStyles = (cssStyles: string | null | undefined) => {
  useEffect(() => {
    if (!cssStyles) return;

    const style = window.document.createElement("style");

    // Append style element to head
    window.document.head.appendChild(style);
    style.textContent = cssStyles;

    return () => style.remove();
  }, [cssStyles]);
};
