import {createSlice} from "@reduxjs/toolkit";

import {ILocalBundleProduct} from "~/model/DAO/IBundleProduct";

import {resetAll} from "./global";

export interface LocalBundleProductState {
  localBundleProduct: ILocalBundleProduct;
  shouldCreateNewOnSubmit: boolean;
}

const initialState: LocalBundleProductState = {
  localBundleProduct: {} as ILocalBundleProduct,
  shouldCreateNewOnSubmit: false,
};

export const LocalBundleProductSlice = createSlice({
  name: "localBundleProduct",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setStep: (state, action) => ({
      ...state,
      localBundleProduct: {
        ...state.localBundleProduct,
        ...action.payload,
      },
    }),
    resetAllSteps: (state) => ({
      ...state,
      localBundleProduct: {...initialState.localBundleProduct},
    }),
    toggleShouldCreateNewOnSubmit: (state) => ({
      ...state,
      shouldCreateNewOnSubmit: !state.shouldCreateNewOnSubmit,
    }),
  },
});

export const LocalBundleProductActions = LocalBundleProductSlice.actions;

export default LocalBundleProductSlice.reducer;
