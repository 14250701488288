import {BmSimplePageContent} from "~/layouts/pageContents";
import {BmSimplePageLayout} from "~/layouts/pageLayouts";

import {NotFoundContent} from "./container/NotFoundContent/NotFoundContent";

const NotFoundPublic: React.FC = () => {
  return (
    <BmSimplePageLayout>
      <BmSimplePageContent>
        <NotFoundContent />
      </BmSimplePageContent>
    </BmSimplePageLayout>
  );
};

export default NotFoundPublic;
