import {createContext, useContext} from "react";

import {CompanySummaryFragment} from "~/api/generated";

type ICompanyInfoContext = CompanySummaryFragment | null | undefined;

const CompanyInfoContext = createContext<ICompanyInfoContext>(undefined);
export const CompanyInfoContextProvider = CompanyInfoContext.Provider;

export const useCompanyInfoContext = () => useContext(CompanyInfoContext);
