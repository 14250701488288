import {css} from "@emotion/css";

import {theme} from "~/ui/theme";

export const styles = {
  root: css`
    padding: 24px 12px;

    ${theme.breakpoints.up("sm")} {
      padding: 35px 24px;
    }
    ${theme.breakpoints.up("md")} {
      padding: 35px 168px;
    }
  `,
};
