import React from "react";
import {SxProps, Theme} from "@mui/material";
import Box from "@mui/material/Box";

import {designTokens} from "~/ui";

import {LabelVariant} from "./ILabel";
import {getLabelStylesByVariant} from "./Label.styles";

export interface BmLabelProps {
  className?: string;
  sx?: SxProps<Theme>;
  variant?: LabelVariant;
  component?: React.ElementType;
  children: React.ReactNode;
}

export const BmLabel: React.FC<BmLabelProps> = ({className, sx, variant = "regular", component = "span", children}) => {
  return (
    <Box
      component={component}
      className={className}
      sx={{
        display: "inline-block",
        lineHeight: designTokens.lineHeight.normal,
        ...getLabelStylesByVariant(variant),
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
