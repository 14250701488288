import React from "react";
import {cx} from "@emotion/css";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import {BmChevronIcon} from "~/components/icons/simpleIcons";
import {designColors} from "~/ui";

import {styles} from "./SidebarItemContent.styles";

interface SidebarItemContentProps {
  text: string;
  hasChildren?: boolean;
  isOpen?: boolean;
  className?: string;
  badge?: React.ReactNode;
  icon?: React.ReactElement;
}

export const SidebarItemContent = React.forwardRef<HTMLDivElement, SidebarItemContentProps>(function SidebarItemContent(
  {text, hasChildren, isOpen, className, badge, icon, ...rest},
  ref,
) {
  return (
    <Box ref={ref} {...rest} className={cx(styles.root, className)}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText>
        <Stack component="span" direction="row" spacing={0.5}>
          <Box component="span" className="list-item-text">
            {text}
          </Box>
          {badge}
        </Stack>
      </ListItemText>

      {hasChildren && (
        <BmChevronIcon
          id={`chevron-icon-${text}`}
          direction={isOpen ? "up" : "down"}
          color={designColors.white}
          size="xs"
        />
      )}
    </Box>
  );
});
