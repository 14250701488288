import {css} from "@emotion/css";

import {designColors} from "~/ui";

export const styles = {
  table: (variant: "white" | "grey") => css`
    width: 100%;
    table-layout: fixed;
    ${variant === "grey" &&
    css`
      /* for safari */
      background-color: ${designColors.grey_2};
      thead tr {
        border-bottom: 1px solid ${designColors.grey_2};
        /* for safari */
        background-color: ${designColors.grey_2};
      }

      td {
        background-color: ${designColors.grey_1};
      }
    `}
  `,
};
