import {cx} from "@emotion/css";

import {designColors} from "~/ui";

import {BmCheckIcon} from "../CheckIcon";
import {simpleIconStyles} from "../../styles";
import {IIconBase} from "../../../_common/types";
import {styles} from "./styles";

export interface BmFakeBadgeProps extends IIconBase {
  completed?: boolean;
  inactive?: boolean;
  text: string;
}

/* I called it fake because it looks and behaves like an icon, not like a badge */
export const BmFakeBadgeIcon: React.FC<BmFakeBadgeProps> = ({id, size = "lg", completed, inactive, text}) => {
  return (
    <div className={cx(simpleIconStyles(size), styles.root(!completed && inactive))}>
      {completed ? <BmCheckIcon id={id} color={designColors.white} /> : text}
    </div>
  );
};
