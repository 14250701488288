// Source: https://github.com/kripod/react-hooks/blob/master/packages/state-hooks/src/usePrevious.ts
import {clone} from "ramda";
import {useEffect, useRef} from "react";

/**
 * Tracks previous state of a value.
 *
 * @param value Props, state or any other calculated value.
 * @returns Value from the previous render of the enclosing component.
 *
 * @example
 * function Component() {
 *   const [count, setCount] = useState(0);
 *   const prevCount = usePrevious(count);
 *   // ...
 *   return `Now: ${count}, before: ${prevCount}`;
 * }
 */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = clone(value);
  });
  return ref.current;
};
