import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import {BmCommonTitleSmall} from "~/components/titles";

export interface BmTableSectionProps {
  className?: string;
  title: string;
  children: React.ReactNode;
}

export const BmTableSection: React.FC<BmTableSectionProps> = ({className, title, children}) => {
  return (
    <Box component="section" className={className}>
      <Box pt={3.5} pb={3.5} component="header">
        <BmCommonTitleSmall>{title}</BmCommonTitleSmall>
      </Box>

      <Divider sx={{mb: 2.5}} />
      {children}
    </Box>
  );
};
