import {Box, Stack, SxProps, Theme, Typography} from "@mui/material";
import {useMemo} from "react";

import {designConstants, designTokens, getPaddingBySpacingSize, SpacingSize} from "~/ui";
import {BmLabel} from "~/components/labels";

import {getColorsByPreset} from "../_common/helpers";
import {BannerColorPresetType, IBannerColors} from "../_common/types";

export interface BmBaseInfoBannerProps {
  id?: string;
  className?: string;
  sx?: SxProps<Theme>;
  bodySx?: SxProps<Theme>;
  colorPreset?: BannerColorPresetType;
  spacingSize?: SpacingSize;
  title?: React.ReactNode;
  description?: string;
  subtitle?: string;
  leftColumn?: React.ReactNode;
  rightColumn?: React.ReactNode;
  footer?: React.ReactNode;
  customColors?: Partial<IBannerColors>;
}

export const BmBaseInfoBanner: React.FC<BmBaseInfoBannerProps> = ({
  id,
  className,
  sx,
  bodySx,
  colorPreset = "grey",
  spacingSize = "md",
  title,
  description,
  subtitle,
  leftColumn,
  rightColumn,
  footer,
  customColors,
}) => {
  const {backgroundColor, borderColor, titleColor} = useMemo(
    () => ({...getColorsByPreset(colorPreset), ...(customColors || {})}),
    [customColors, colorPreset],
  );

  return (
    <Box
      sx={{
        p: getPaddingBySpacingSize(spacingSize),
        backgroundColor,
        border: `1px solid ${borderColor}`,
        borderRadius: designConstants.borderRadius,
        ...sx,
      }}
      className={className}
      id={id}
    >
      <Stack direction="row" spacing={2} useFlexGap>
        {leftColumn && <Box flex="0">{leftColumn}</Box>}
        <Stack direction="row" spacing={2} flexWrap="wrap" flex="auto" useFlexGap sx={{...bodySx}}>
          <Stack flex="auto" spacing={0.5} useFlexGap>
            {title && (
              <BmLabel variant="semiBold" sx={{color: titleColor}}>
                {title}
              </BmLabel>
            )}
            {subtitle && <BmLabel sx={{fontSize: designTokens.fontSize.xsm}}>{subtitle}</BmLabel>}
            {description && <Typography dangerouslySetInnerHTML={{__html: description}} />}
            {footer && <Box mt={1.5}>{footer}</Box>}
          </Stack>
          {rightColumn && <Box flex="0">{rightColumn}</Box>}
        </Stack>
      </Stack>
    </Box>
  );
};
