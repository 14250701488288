import {Box, Stack} from "@mui/material";
import {cx} from "@emotion/css";

import {BmCommonTitle} from "~/components/titles";
import {BmUnstyledSvgButton} from "~/components/buttons";
import {BmCloseIcon} from "~/components/icons/simpleIcons";

import {IModalContent} from "../IModalContent";

export const BmDeleteProductModalContent: React.FC<IModalContent> = ({
  headerClassName,
  onClose,
  title,
  children,
  footerButtons,
}) => {
  return (
    <Stack p={4} spacing={4} useFlexGap>
      <Box
        component="header"
        className={cx(headerClassName)}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <BmCommonTitle sx={{mb: 0}}>{title}</BmCommonTitle>
        <BmUnstyledSvgButton id="close-modal-button" onClick={onClose}>
          <BmCloseIcon id="close-modal-icon" />
        </BmUnstyledSvgButton>
      </Box>
      <Box>{children}</Box>
      <Stack spacing={2} direction="row" component="footer" useFlexGap>
        {footerButtons}
      </Stack>
    </Stack>
  );
};
