import {designTokens} from "~/ui";

import {BmPrimaryRouterLink} from "../PrimaryRouterLink";

export interface BmSectionTitleLinkButtonProps {
  to: string;
  children: React.ReactNode;
}

export const BmSectionTitleLinkButton: React.FC<BmSectionTitleLinkButtonProps> = ({to, children}) => (
  <BmPrimaryRouterLink
    sx={{
      fontSize: designTokens.fontSize.md,
      fontWeight: designTokens.fontWeight.semiBold,
      lineHeight: "1.8rem",
    }}
    to={to}
  >
    {children}
  </BmPrimaryRouterLink>
);
