import {css} from "@emotion/css";

import {designTokens, designUtils} from "~/ui";
import {theme} from "~/ui/theme";

export const styles = {
  tableCell: (newMobileDesign: boolean, rightAlign?: boolean, optionsCell?: boolean) => css`
    &.MuiTableCell-root {
      ${designUtils.baseFontStyles};
      position: relative;
      font-weight: ${designTokens.fontWeight.regular};
      padding: 12px 6px;
      transition: ${designUtils.transition(0.3)};
      border-bottom: none;
      vertical-align: top;

      ${optionsCell &&
      css`
        padding: 4px;
      `}

      ${rightAlign &&
      css`
        text-align: right;
      `}

      ${theme.breakpoints.down("md")} {
        display: flex;
        gap: 12px;
        width: auto !important;
        height: auto;
        padding: ${newMobileDesign ? "6px 12px" : "6px 0"};

        text-align: left;

        & > * {
          flex: 1;
        }
      }

      .MuiTableSortLabel-root {
        vertical-align: top;
        .MuiTableSortLabel-icon {
          display: none;
          //this margin is needed because the svg has empty spaces on the sides
          margin-right: -2px;
        }
        &.Mui-active .MuiTableSortLabel-icon {
          display: inline-block;
        }
      }
    }
  `,
  label: css`
    display: none;

    ${theme.breakpoints.down("md")} {
      display: block;
      word-break: break-word;
      font-weight: ${designTokens.fontWeight.semiBold};
    }
  `,
  text: (optionsCell?: boolean) => css`
    display: block;
    word-break: break-word;

    ${optionsCell && theme.breakpoints.down("md")} {
      display: none;
    }
  `,
};
