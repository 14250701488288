import {css} from "@emotion/css";

import {designTokens} from "~/ui";

export const styles = {
  root: css`
    p {
      margin: 0;
    }
    .MuiTablePagination-selectLabel,
    .MuiTablePagination-select {
      font-size: ${designTokens.fontSize.md};
      line-height: 21px;
    }
    .MuiTablePagination-select.MuiSelect-select {
      min-height: auto;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  `,
};
