import {css} from "@emotion/css";

import {designColors, designTokens, designUtils} from "~/ui";

export const styles = {
  arrowWhiteButton: css`
    &.MuiLink-button {
      ${designUtils.baseFontStyles};
      display: flex;
      align-items: center;
      color: ${designColors.white};
      font-weight: ${designTokens.fontWeight.semiBold};
      span span {
        display: block;
        font-size: ${designTokens.fontSize.xs};
        font-weight: ${designTokens.fontWeight.regular};
      }
    }
  `,
  icon: css`
    margin-left: 6px;
  `,
};
