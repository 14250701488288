import {css} from "@emotion/css";

import {designColors} from "~/ui";

export const styles = {
  whiteButton: (error?: boolean) => css`
    &.MuiButton-root {
      &,
      &:hover {
        color: ${designColors.black};
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid ${designColors.grey_2};

        ${error &&
        css`
          color: ${designColors.error};
          border: 1px solid ${designColors.error};
        `}
      }
    }
  `,
};
