import {lazy} from "react";

import {GuestRouteFallback, PrivateRouteFallback, SharedRouteFallback} from "~/components/suspenseFallbacks";

import {Route} from "./types";

export const createGuestRoute = (
  PageComponent: React.ComponentType<any>,
  path: string,
  options?: Omit<Route, "PageComponent" | "path">,
): Route => {
  return {
    PageComponent,
    path,
    isPrivate: false,
    exact: true,
    strict: true,
    restrictedFromAuthorized: true,
    SuspenseFallbackComponent: GuestRouteFallback,
    ...options,
  };
};

export const createSharedAccessRoute = (
  PageComponent: React.ComponentType<any>,
  path: string,
  options?: Omit<Route, "PageComponent" | "path">,
): Route => {
  return {
    PageComponent,
    path,
    isPrivate: false,
    exact: true,
    strict: true,
    restrictedFromAuthorized: false,
    SuspenseFallbackComponent: SharedRouteFallback,
    ...options,
  };
};

export const createPrivateRoute = (
  PageComponent: React.ComponentType<any>,
  path: string,
  options?: Omit<Route, "PageComponent" | "path">,
): Route => {
  return {
    PageComponent,
    path,
    isPrivate: true,
    exact: true,
    strict: true,
    SuspenseFallbackComponent: PrivateRouteFallback,
    ...options,
  };
};

const lazyRetry = <T extends React.ComponentType<any>>(
  componentImport: () => Promise<{default: T}>,
  name?: string,
): Promise<{default: T}> => {
  const itemKey = name === undefined ? "retry-lazy-refreshed" : `retry-${name}-refreshed`;
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(itemKey) || "false");

    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(itemKey, "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(itemKey, "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

// Template for lazy import with lazyRetry
export const createLazyPageComponent = (path: string) => {
  return lazy(() =>
    lazyRetry(
      () => import(/* webpackChunkName: "[request]" */ `~/pages/${path.replace("~/pages/", "")}`),
      path.split("/").pop(),
    ),
  );
};
