import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import {BmSkeleton} from "../Skeleton/Skeleton";

interface BmLayoutSkeletonProps {
  maxWidth?: string;
  sections?: number[]; // Number of skeletons in each section
}

export const BmLayoutSkeleton: React.FC<BmLayoutSkeletonProps> = ({maxWidth = "100%", sections = [3, 3, 2]}) => {
  return (
    <Box maxWidth={maxWidth}>
      <Box mb="50px">
        <BmSkeleton height={35} />
      </Box>
      {sections.map((skeletonCount, sectionIndex) => (
        <Stack key={sectionIndex} mb="30px" spacing={1}>
          {Array.from({length: skeletonCount}).map((_, index) => (
            <BmSkeleton key={index} height={35} />
          ))}
        </Stack>
      ))}
    </Box>
  );
};
