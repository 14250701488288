import {useState} from "react";

import {DeepNonNullable} from "~/model/helperTypes/generic";

import {ITableSortingParams, SortDirectionType} from "./ITableSorting";

type ComparatorType<Key extends keyof any> = (
  a: {[key in Key]: number | string | undefined | null | object},
  b: {[key in Key]: number | string | undefined | null | object},
) => number;

type GetComparatorType = <Key extends keyof any>(sortDirection: SortDirectionType, sortBy: Key) => ComparatorType<Key>;

type UseFrontendTableSortingReturnTuple = [
  DeepNonNullable<ITableSortingParams>,
  {
    sortToggle: (key: string, isSortNumber?: boolean) => void;
    getComparator: GetComparatorType;
  },
];

export const useFrontendTableSorting = (): UseFrontendTableSortingReturnTuple => {
  const [sortDirection, setSortDirection] = useState<SortDirectionType>("asc");
  const [sortBy, setSortBy] = useState<string>("");
  const [isSortNumber, setIsSortNumber] = useState<boolean | undefined>(false);

  const sortToggle = (nextSortBy: string, isSortNumber?: boolean) => {
    const isAsc = sortBy === nextSortBy && sortDirection === "asc";
    const nextSortDirection = isAsc ? "desc" : "asc";
    setSortDirection(nextSortDirection);
    setSortBy(nextSortBy);
    setIsSortNumber(isSortNumber);
  };

  const descComparator = <T>(a: T, b: T, sortBy: keyof T) => {
    const aVal = isSortNumber ? parseFloat(a[sortBy] + "") : a[sortBy];
    const bVal = isSortNumber ? parseFloat(b[sortBy] + "") : b[sortBy];

    if (typeof aVal === "string" && typeof bVal === "string") {
      return bVal.localeCompare(aVal);
    }

    if (bVal < aVal) {
      return -1;
    }
    if (bVal > aVal) {
      return 1;
    }
    return 0;
  };

  const getComparator = <Key extends keyof any>(sortDirection: SortDirectionType, sortBy: Key): ComparatorType<Key> => {
    return sortDirection === "desc" ? (a, b) => descComparator(a, b, sortBy) : (a, b) => -descComparator(a, b, sortBy);
  };

  return [
    {sortDirection, sortBy},
    {sortToggle, getComparator},
  ];
};
