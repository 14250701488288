import {BmCardViewSection} from "~/components/templates";
import {BmUnstyledSvgButton} from "~/components/buttons";
import {BmCloseIcon} from "~/components/icons/simpleIcons";

import {IModalContent} from "../IModalContent";

export const BmCardViewModalContent: React.FC<IModalContent> = ({title, closeButton, onClose, children}) => {
  return (
    <BmCardViewSection
      title={title}
      titleRight={
        closeButton ? (
          <BmUnstyledSvgButton id="close-modal-button" onClick={onClose}>
            <BmCloseIcon id="close-modal-icon" />
          </BmUnstyledSvgButton>
        ) : undefined
      }
    >
      {children}
    </BmCardViewSection>
  );
};
