import {css} from "@emotion/css";

export const styles = {
  link: (color: string) => css`
    &.MuiTypography-root {
      color: ${color};
      text-decoration-color: ${color};
    }
  `,
};
