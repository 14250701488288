import {cx} from "@emotion/css";

import {designColors} from "~/ui";
import {ReactComponent as SvgChevronDown} from "~/assets/svg/chevron-down.svg";

import {simpleIconStyles} from "../../styles";
import {commonStyles} from "../../../_common/styles";
import {IIconBase} from "../../../_common/types";

export const BmChevronDownIcon: React.FC<IIconBase> = ({id, size = "xs", color = designColors.black, className}) => {
  return <SvgChevronDown id={id} className={cx(simpleIconStyles(size), commonStyles.color(color), className)} />;
};
