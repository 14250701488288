import MuiLink from "@mui/material/Link";
import {Link} from "react-router-dom";
import {cx} from "@emotion/css";

import {designColors} from "~/ui";

import {ILink} from "../ILink";
import {styles} from "./PrimaryRouterLink.styles";

interface RouterLinkProps extends ILink {
  to: string;
}

export const BmPrimaryRouterLink: React.FC<RouterLinkProps> = ({
  to,
  underline = "always",
  className,
  sx,
  color = designColors.primary,
  target,
  children,
  ...restProps
}) => {
  return (
    <MuiLink
      {...restProps}
      className={cx(styles.link(color), className)}
      sx={sx}
      component={Link}
      to={to}
      underline={underline}
      target={target}
    >
      {children}
    </MuiLink>
  );
};
