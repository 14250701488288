import {createSlice} from "@reduxjs/toolkit";

import {resetAll} from "./global";

export interface UiState {
  openedSubMenu: string | null;
}

const initialState: UiState = {
  openedSubMenu: null,
};

export const UiSlice = createSlice({
  name: "ui",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    toggleSidebarSubList: (state, action) => {
      if (state.openedSubMenu === action.payload) {
        state.openedSubMenu = null;
      } else {
        state.openedSubMenu = action.payload;
      }
    },
    openSidebarSubList: (state, action) => {
      if (state.openedSubMenu === action.payload) return;
      state.openedSubMenu = action.payload;
    },
  },
});

export const UiActions = UiSlice.actions;

export default UiSlice.reducer;
