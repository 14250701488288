import {cx} from "@emotion/css";
import {SxProps, Theme, Box, useTheme, useMediaQuery} from "@mui/material";

import {designColors, designConstants, getPaddingBySpacingSize} from "~/ui";

export interface BmRegularPaperProps {
  className?: string;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

export const BmRegularPaper: React.FC<BmRegularPaperProps> = ({className, sx, children}) => {
  const theme = useTheme();
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      className={cx(className)}
      sx={{
        p: getPaddingBySpacingSize(isLowerThanMd ? "md" : "lg"),
        borderRadius: designConstants.borderRadius,
        backgroundColor: designColors.white,
        boxShadow: designConstants.boxShadow,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
