import {cx} from "@emotion/css";

import {designColors} from "~/ui";
import {ReactComponent as SvgEditSquare} from "~/assets/svg/edit-2.svg";

import {simpleIconStyles} from "../styles";
import {commonStyles} from "../../_common/styles";
import {IIconBase} from "../../_common/types";

export const BmEditSquareIcon: React.FC<IIconBase> = ({id, size = "sm", color = designColors.black, className}) => {
  return <SvgEditSquare id={id} className={cx(simpleIconStyles(size), commonStyles.color(color), className)} />;
};
