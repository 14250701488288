import {Link} from "react-router-dom";

import {BmMenuButton} from "~/components/buttons";
import {BmNewLogo} from "~/components/icons/otherIcons";
import {useIsMobile} from "~/hooks";
import {ORDERS_PATH} from "~/modules/routing";
import {designColors} from "~/ui";

import {styles} from "./MobileHeader.styles";

interface MobileHeaderProps {
  onMenuIconClick: VoidFunction;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({onMenuIconClick}) => {
  const [isTablet] = useIsMobile();
  return (
    <div className={styles.header}>
      {isTablet && <BmMenuButton onClick={onMenuIconClick} />}
      <Link to={ORDERS_PATH} className={styles.logoLink}>
        <BmNewLogo id="mobile-header-logo-icon" color={designColors.white} />
      </Link>
    </div>
  );
};
