import {cx} from "@emotion/css";

import {designColors} from "~/ui";
import {ReactComponent as SvgChevronUp} from "~/assets/svg/chevron-up.svg";

import {simpleIconStyles} from "../../styles";
import {commonStyles} from "../../../_common/styles";
import {IIconBase} from "../../../_common/types";

export const BmChevronUpIcon: React.FC<IIconBase> = ({id, size = "xs", color = designColors.black, className}) => {
  return <SvgChevronUp id={id} className={cx(simpleIconStyles(size), commonStyles.color(color), className)} />;
};
