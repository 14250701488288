import {createTheme, PaletteColor, PaletteColorOptions} from "@mui/material/styles";
import {rgba} from "polished";

import {getHoverColor} from "~/utils/colors";

import {designColors} from "./designColors";
import {designTokens} from "./designTokens";

declare module "@mui/material/styles" {
  interface TypeText {
    grey: string;
    error: string;
  }

  interface Palette {
    text: TypeText;
    white: Palette["primary"];
    brand: PaletteColor;
  }

  interface PaletteOptions {
    text?: Partial<TypeText>;
    white: PaletteColorOptions;
    brand: PaletteColorOptions;
  }

  interface TypographyVariants {
    body0: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body0?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body0: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    white: true;
    brand: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: designColors.primary,
      light: getHoverColor(designColors.primary, "lighter"),
      dark: getHoverColor(designColors.primary, "darker"),
    },
    brand: {
      main: designColors.brand,
      light: getHoverColor(designColors.brand, "lighter", 0.16),
      dark: getHoverColor(designColors.brand, "darker"),
    },
    white: {
      main: designColors.white,
      dark: getHoverColor(designColors.white, "darker"),
    },
    text: {
      grey: designColors.grey_5,
      error: designColors.error,
      disabled: designColors.grey_4,
    },
    error: {
      main: designColors.error,
    },
  },
  typography: {
    body0: {
      fontSize: designTokens.fontSize.base,
      fontWeight: designTokens.fontWeight.regular,
      lineHeight: designTokens.lineHeight.normal,
    },
    h2: {
      fontSize: designTokens.fontSize.xl,
      fontWeight: designTokens.fontWeight.bold,
      lineHeight: "30px",
    },
    h3: {
      fontSize: designTokens.fontSize.lg,
      fontWeight: designTokens.fontWeight.semiBold,
      lineHeight: "30px",
    },
    fontFamily: designTokens.fontFamily,
  },
  spacing: 6,
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ownerState}) => ({
          ...(ownerState.color === "brand" && {
            "&:hover": {
              backgroundColor: getHoverColor(designColors.brand, "lighter"),
            },
          }),
        }),
        containedPrimary: {
          "&.Mui-disabled": {
            backgroundColor: designColors.primary,
            color: rgba(designColors.white, 0.4),
          },
          ".MuiLoadingButton-loadingIndicator": {
            color: rgba(designColors.white, 0.4),
          },
        },
        outlinedPrimary: {
          "&.Mui-disabled": {
            borderColor: designColors.primary,
            color: designColors.primary,
            opacity: 0.4,
          },
          ".MuiLoadingButton-loadingIndicator": {
            color: designColors.primary,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: designColors.primary,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: designColors.grey_1,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: designColors.colorInk,
          fontFamily: designTokens.fontFamily,
          borderBottom: `1px solid ${designColors.grey_1}`,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // Map the new variant to render a <p> by default
          body0: "p",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1100,
      xl: 1536,
    },
  },
});
