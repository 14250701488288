import {ZendeskAPI} from "react-zendesk";
import {useEffect} from "react";

import {useCompanyInfoContext} from "~/modules/company";

export const useZendeskPrefill = () => {
  const companyData = useCompanyInfoContext();

  useEffect(() => {
    if (!companyData) return;

    ZendeskAPI("webWidget", "identify", {
      name: companyData.contactName, // <-- Maker contact person
      email: companyData.contactEmail, // <-- Maker contact person
      organization: companyData.name, // <-- Maker
    });
    ZendeskAPI("webWidget", "prefill", {
      name: {
        value: companyData.contactName, // <-- Maker contact person
        readOnly: false,
      },
      email: {
        value: companyData.contactEmail, // <-- Maker contact person
        readOnly: true,
      },
    });
  }, [companyData]);
};
