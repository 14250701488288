import {css} from "@emotion/css";

import {designColors, designTokens, designUtils} from "~/ui";

export const styles = {
  contextMenuitem: css`
    &.MuiMenuItem-root {
      padding: 6px 12px;
      transition: ${designUtils.transition()};
      .MuiListItemIcon-root {
        min-width: 18px;
        margin-right: 8px;
      }
      .MuiListItemText-root span {
        color: ${designColors.colorInk};
        font-size: ${designTokens.fontSize.sm};
        font-weight: ${designTokens.fontWeight.semiBold};
        text-decoration: none;
      }
    }
  `,
  contextMenuLink: css`
    &.MuiLink-root {
      display: flex;
      align-items: center;
      padding-right: 48px;
      width: 100%;
      .MuiListItemText-root {
        text-align: left;
      }
    }
  `,
};
