import {css} from "@emotion/css";

import {designConstants, designTokens, designUtils} from "~/ui";

export const commonStyles = {
  button: css`
    &.MuiButton-root {
      ${designUtils.baseFontStyles};
      height: 42px;
      padding: 12px 16px;

      border-radius: ${designConstants.borderRadius};

      font-weight: ${designTokens.fontWeight.semiBold};
      text-transform: none;
    }
    &.MuiLoadingButton-loading {
      min-width: 100px;
    }
  `,
};
