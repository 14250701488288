import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import {SxProps, Theme} from "@mui/material";

import {designColors, designTokens} from "~/ui";

export interface BmHelperTextProps {
  className?: string;
  sx?: SxProps<Theme>;
  error?: boolean;
  dangerouslySetInnerHTML?:
    | {
        __html: string;
      }
    | undefined;
  children?: React.ReactNode | undefined;
}

export const BmHelperText: React.FC<BmHelperTextProps> = ({
  className,
  sx,
  error = false,
  dangerouslySetInnerHTML,
  children,
}) => {
  return (
    <Box
      component={FormHelperText}
      className={className}
      sx={{
        fontSize: designTokens.fontSize.sm,
        fontWeight: designTokens.fontWeight.regular,
        lineHeight: designTokens.lineHeight.normal,
        color: error ? designColors.error : designColors.grey_5,
        ...sx,
      }}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </Box>
  );
};
