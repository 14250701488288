import {css} from "@emotion/css";

import {designColors, designUtils} from "~/ui";

export const styles = {
  link: css`
    &.MuiTypography-root {
      ${designUtils.baseFontStyles};
      display: inline-block;
      color: ${designColors.colorInk};
      text-decoration-color: ${designColors.colorInk};
      cursor: pointer;
    }
  `,
};
