import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({baseUrl: process.env.BEMAKERS_API_URL}),
  endpoints: (build) => ({
    createToken: build.mutation({
      query: ({email, password}) => ({
        url: "/auth_tokens",
        method: "POST",
        body: {email, password},
      }),
    }),
    createUser: build.mutation({
      query: ({email, password}) => ({
        url: "/users",
        method: "POST",
        body: {user: {email, password}},
      }),
    }),
    resetPassword: build.mutation({
      query: ({email}) => ({
        url: "/password_resets",
        method: "POST",
        body: {email},
      }),
    }),
    setNewPassword: build.mutation({
      query: ({token, password, password_confirmation}) => ({
        url: `/password_resets/${token}`,
        method: "PUT",
        body: {user: {password, password_confirmation}},
      }),
    }),
    getInviteData: build.query({
      query: (token) => ({
        url: `/invitations/${token}`,
        method: "GET",
      }),
    }),
    confirmInvite: build.mutation({
      query: ({token, password, email, name}) => ({
        url: `/invitations/${token}`,
        method: "PUT",
        body: {invitation: {password, email, name}},
      }),
    }),
  }),
});

export const {
  useCreateTokenMutation,
  useCreateUserMutation,
  useResetPasswordMutation,
  useSetNewPasswordMutation,
  useGetInviteDataQuery,
  useConfirmInviteMutation,
} = authApi;
