import {css} from "@emotion/css";

import {theme} from "~/ui/theme";

export const styles = {
  root: (newMobileDesign: boolean) => css`
    ${newMobileDesign &&
    css`
      &.MuiTableBody-root {
        ${theme.breakpoints.down("md")} {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
      }
    `}
  `,
};
