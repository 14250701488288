import {css} from "@emotion/css";

import {designColors, designTokens} from "~/ui";

export const styles = {
  dataGrid: css`
    &.MuiDataGrid-root {
      border: none;
      border-bottom: 1px solid ${designColors.grey_2};
      .MuiDataGrid-row.Mui-selected,
      .MuiDataGrid-row:hover,
      .MuiDataGrid-row.Mui-selected:hover {
        background: none;
      }
      .MuiDataGrid-columnSeparator {
        display: none;
      }
      .MuiDataGrid-columnHeaders {
        background-color: ${designColors.grey_1};
        border-radius: 0;
      }
      .MuiFormControl-root {
        margin-bottom: 0;
      }
      .MuiOutlinedInput-root {
        border-radius: 0;
      }
      .MuiOutlinedInput-input {
        padding: 16.5px 9px;
      }
      .MuiOutlinedInput-notchedOutline,
      label[data-shrink="true"]:not(.Mui-focused, .Mui-error)
        + .MuiInputBase-formControl
        .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
        outline: none;
      }
      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
        outline: none;
      }
      .MuiDataGrid-columnHeadersInner {
        .MuiDataGrid-columnHeader {
          .MuiDataGrid-columnHeaderTitle {
            font-size: ${designTokens.fontSize.base};
            font-weight: ${designTokens.fontWeight.semiBold};
          }
        }
      }
      .MuiDataGrid-columnHeader {
        padding: 12px 6px;
        &:focus {
          outline: none;
        }
      }
      .MuiDataGrid-row {
        .MuiDataGrid-cell {
          padding: 12px 6px;
          &--textRight {
            input {
              text-align: right;
            }
          }
        }
      }
      .MuiDataGrid-cell {
        font-size: ${designTokens.fontSize.base};
        border-bottom: 1px solid ${designColors.grey_2};
        &:focus {
          outline: none;
        }
        &.MuiDataGrid-cell--editing {
          box-shadow: none;
        }
        &.MuiDataGrid-cell--editable {
          cursor: pointer;
          background-color: ${designColors.blue_1};
          &:focus,
          &:focus-within {
            outline: solid ${designColors.primary} 2px;
          }
          &:focus-within {
            outline-offset: -2px;
          }
        }
      }
      .MuiDataGrid-editInputCell {
        font-size: ${designTokens.fontSize.base};
        input {
          padding: 0 9px;
        }
      }
    }
  `,
};
