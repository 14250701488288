import {useMemo, useState} from "react";

import {useDidUpdate} from "~/hooks";
import {FieldErrorType} from "~/model/helperTypes/Errors";

export const useFormBeenSubmitted = (submitLoading: boolean, backendErrors: FieldErrorType[] | null): [boolean] => {
  const [isFormBeenSubmitted, setFormBeenSubmitted] = useState(false);

  const isFormBeenSubmittedSuccessfully = useMemo(
    () => isFormBeenSubmitted && !backendErrors?.length,
    [backendErrors?.length, isFormBeenSubmitted],
  );

  useDidUpdate(() => {
    if (submitLoading) return;
    setFormBeenSubmitted(true);
  }, [submitLoading]);

  return [isFormBeenSubmittedSuccessfully];
};
