import {SxProps, Theme} from "@mui/material";
import Typography from "@mui/material/Typography";

import {designColors, designTokens} from "~/ui";

interface BmTableDescriptionProps {
  className?: string;
  sx?: SxProps<Theme>;
  description: string;
}

export const BmTableDescription: React.FC<BmTableDescriptionProps> = ({className, sx, description}) => (
  <Typography
    variant="h3"
    className={className}
    sx={{
      mb: 1,
      color: designColors.grey_5,
      fontSize: designTokens.fontSize.base,
      fontWeight: designTokens.fontWeight.regular,
      lineHeight: designTokens.lineHeight.normal,
      ...sx,
    }}
  >
    {description}
  </Typography>
);
