import {transparentize, parseToRgb, rgbToColorString} from "polished";

export const blendColors = (baseColor: string, overlayColor: string, opacity: number) => {
  const baseRgb = parseToRgb(baseColor);
  const transparentOverlay = transparentize(1 - opacity, overlayColor);
  const overlayRgb = parseToRgb(transparentOverlay);

  const blendedRgb = {
    red: Math.round((1 - opacity) * baseRgb.red + opacity * overlayRgb.red),
    green: Math.round((1 - opacity) * baseRgb.green + opacity * overlayRgb.green),
    blue: Math.round((1 - opacity) * baseRgb.blue + opacity * overlayRgb.blue),
  };

  return rgbToColorString(blendedRgb);
};

export const getHoverColor = (baseColor: string, lightness: "darker" | "lighter", opacity: number = 0.08): string =>
  blendColors(baseColor, lightness === "darker" ? "#000000" : "#ffffff", opacity);
