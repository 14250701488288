import {Fragment, useMemo} from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {Stack, SxProps, Theme} from "@mui/material";

import {BmHelperText} from "~/components/helperText";
import {BmRegularPaper} from "~/components/papers";
import {BmCommonTitleSmall} from "~/components/titles";
import {designTokens} from "~/ui";

export interface BmCardViewSectionProps {
  className?: string;
  sx?: SxProps<Theme>;
  wrapByPaper?: boolean;
  title: React.ReactNode;
  titleRight?: React.ReactNode;
  subtitle?: string;
  children: React.ReactNode;
}

export const BmCardViewSection: React.FC<BmCardViewSectionProps> = ({
  className,
  sx,
  wrapByPaper = true,
  title,
  titleRight,
  subtitle,
  children,
}) => {
  const Wrapper = useMemo(() => (wrapByPaper ? BmRegularPaper : Fragment), [wrapByPaper]);
  return (
    <Box component="section" className={className} sx={sx}>
      <Wrapper {...(wrapByPaper ? {sx: {height: "100%"}} : {})}>
        <Box component="header">
          <Stack direction="row" justifyContent="space-between" spacing={2} useFlexGap>
            <BmCommonTitleSmall>{title}</BmCommonTitleSmall>
            {titleRight}
          </Stack>

          {subtitle && <BmHelperText sx={{fontWeight: designTokens.fontWeight.regular}}>{subtitle}</BmHelperText>}
        </Box>

        <Divider sx={{mt: 2, mb: 4}} />
        {children}
      </Wrapper>
    </Box>
  );
};
