import {NavigateOptions, useSearchParams} from "react-router-dom-v5-compat";
import {URLSearchParamsInit} from "react-router-dom-v5-compat/dist/react-router-dom";
import {useLocation} from "react-router-dom";
import {useState} from "react";

import {useDidUpdate} from "~/hooks";

export type SetURLSearchParams = (
  nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
  navigateOpts?: NavigateOptions,
) => void;
type UseFixedSearchParamsReturnTuple = [URLSearchParams, SetURLSearchParams];

// this hook fixes the issue with useSearchParams of react-router-dom v6
// the issue - we have empty searchParams on mount, even if they are exists
// so to fix it, we use `search` object from useLocation of react-router-dom v5
export const useFixedSearchParams = (): UseFixedSearchParamsReturnTuple => {
  const {search} = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [localSearchParams, setLocalSearchParams] = useState(new URLSearchParams(search));

  useDidUpdate(() => {
    setLocalSearchParams(searchParams);
  }, [searchParams]);

  return [localSearchParams, setSearchParams];
};
