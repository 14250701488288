import {ToastContainer, Zoom} from "react-toastify";

import {TOAST_TRANSITION} from "~/constants/constants";

import "react-toastify/dist/ReactToastify.min.css";
import {styles} from "./Toaster.styles";

export const BmToast: React.FC = () => {
  return (
    <ToastContainer
      className={styles.toaster}
      position="bottom-center"
      autoClose={TOAST_TRANSITION}
      hideProgressBar={true}
      closeButton={false}
      pauseOnHover={true}
      transition={Zoom}
      limit={4}
    />
  );
};
