import {useEffect} from "react";

type BeforeunloadProps = (evt: BeforeUnloadEvent) => void;

export const useBeforeunload = (fn: BeforeunloadProps) => {
  useEffect(() => {
    window.addEventListener("beforeunload", fn);
    return () => {
      window.removeEventListener("beforeunload", fn);
    };
  }, [fn]);
};
