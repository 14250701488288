import {SxProps, Theme, Stack} from "@mui/material";

import {ILink} from "../../ILink";
import {BmPrimaryLink} from "../PrimaryLink";

export interface BmPrimaryIconLinkProps {
  sx?: SxProps<Theme>;
  href?: string;
  underline?: ILink["underline"];
  target?: ILink["target"];
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  children: React.ReactNode;
}

export const BmPrimaryIconLink: React.FC<BmPrimaryIconLinkProps> = ({
  sx,
  href,
  underline = "none",
  target = "_blank",
  iconLeft,
  iconRight,
  children,
}) => (
  <BmPrimaryLink href={href} underline={underline} target={target} sx={sx}>
    <Stack sx={{alignItems: "center"}} spacing={1} direction="row" useFlexGap>
      {iconLeft}
      {children}
      {iconRight}
    </Stack>
  </BmPrimaryLink>
);
