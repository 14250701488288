import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {MobileHeader} from "~/containers/header/MobileHeader/MobileHeader";
import {Sidebar} from "~/containers/sidebar/Sidebar";
import {useToggle} from "~/hooks";
import {designColors} from "~/ui";

export interface BmDefaultPageLayoutProps {
  children: React.ReactNode;
}

export const BmDefaultPageLayout: React.FC<BmDefaultPageLayoutProps> = ({children}) => {
  const [mobileMenuOpen, mobileMenuToggle] = useToggle(false);
  const theme = useTheme();
  const isLowerThanLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box minHeight="100vh" bgcolor={designColors.off_white}>
      {isLowerThanLg && <MobileHeader onMenuIconClick={mobileMenuToggle} />}
      <Box display="flex">
        <Sidebar mobileMenuOpen={mobileMenuOpen} mobileMenuToggle={mobileMenuToggle} />
        <Box flex="auto">{children}</Box>
      </Box>
    </Box>
  );
};
