import React from "react";
import MuiMenu from "@mui/material/Menu";
import {PopoverOrigin, PopoverProps} from "@mui/material/Popover";
import MuiMenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import {cx} from "@emotion/css";

import {styles} from "./ContextMenu.styles";

export type ContextMenuProps = {
  className?: string;
  isLoading?: boolean;
  open: boolean;
  anchorEl?: PopoverProps["anchorEl"];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  handleClose?: VoidFunction;
  children: React.ReactNode;
};

export const BmContextMenu: React.FC<ContextMenuProps> = ({
  children,
  open,
  anchorEl,
  anchorOrigin = {vertical: "bottom", horizontal: "right"},
  transformOrigin = {vertical: "top", horizontal: "right"},
  handleClose,
  isLoading,
  className,
}) => {
  const {t} = useTranslation();
  return (
    <MuiMenu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      disablePortal
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClick={(e) => e.stopPropagation()}
      className={cx(styles.contextMenu, className)}
    >
      {isLoading ? <MuiMenuItem className={styles.loadingState}>{t("buttons.loading")}</MuiMenuItem> : children}
    </MuiMenu>
  );
};
