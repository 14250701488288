import {designColors, designTokens} from "~/ui";

import {LabelVariant} from "./ILabel";

export const getLabelStylesByVariant = (variant: LabelVariant) => {
  switch (variant) {
    case "bold":
      return {
        color: designColors.colorInk,
        fontSize: designTokens.fontSize.base,
        fontWeight: designTokens.fontWeight.bold,
      };
    case "semiBold":
      return {
        color: designColors.colorInk,
        fontSize: designTokens.fontSize.base,
        fontWeight: designTokens.fontWeight.semiBold,
      };
    case "regular":
    default:
      return {
        color: designColors.grey_5,
        fontSize: designTokens.fontSize.base,
        fontWeight: designTokens.fontWeight.regular,
      };
  }
};
