import {css} from "@emotion/css";

import {designColors, designTokens} from "~/ui";

export const styles = {
  root: css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;

    border-radius: 9px;

    background-color: ${designColors.primary};

    color: ${designColors.white};
    font-size: ${designTokens.fontSize.xsm};
    font-weight: ${designTokens.fontWeight.regular};
    line-height: 14px;

    min-width: 18px;
    height: 18px;
  `,
};
