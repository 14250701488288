import {Stack, SxProps, Theme} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import {designColors, designConstants, designTokens} from "~/ui";
import {BmPrimaryButton} from "~/components/buttons";

export interface BmHeroBannerProps {
  className?: string;
  sx?: SxProps<Theme>;
  label?: string;
  title: string;
  description: string;
  primaryButtonText: string;
  primaryButtonLink: string;
  heroBannerImgSrc: string;
  heroBannerImgSx?: SxProps<Theme>;
}

export const BmHeroBanner: React.FC<BmHeroBannerProps> = ({
  className,
  sx,
  label,
  title,
  description,
  primaryButtonText,
  primaryButtonLink,
  heroBannerImgSrc,
  heroBannerImgSx,
}) => {
  return (
    <Stack
      className={className}
      sx={{
        p: 4,
        position: "relative",
        borderRadius: designConstants.borderRadius,
        backgroundColor: designColors.brown_1,
        overflow: "hidden",
        zIndex: 0,
        minHeight: 312,
        justifyContent: "center",
        ...sx,
      }}
    >
      {label && (
        <Box sx={{position: "absolute", top: 0, left: 0, m: 4}}>
          <Typography
            component="span"
            sx={{
              color: designColors.blue_3,
              fontSize: designTokens.fontSize.xs,
              fontWeight: designTokens.fontWeight.semiBold,
              lineHeight: "18px" /* 163.636% */,
              letterSpacing: "0.385px",
              textTransform: "uppercase",
            }}
          >
            {label}
          </Typography>{" "}
          🎉
        </Box>
      )}
      <Box sx={{maxWidth: "435px"}}>
        <Typography variant="h2" mb={1} dangerouslySetInnerHTML={{__html: title}} />
        <Typography variant="body0" mb={4}>
          {description}
        </Typography>
        <Box>
          <BmPrimaryButton
            onClick={(e) => {
              e.preventDefault();
              window.open(primaryButtonLink, "_blank");
            }}
          >
            {primaryButtonText}
          </BmPrimaryButton>
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          zIndex: -1,
          right: 0,
          opacity: {sm: "0.1", md: "1"},
          display: {xs: "none", sm: "block"},
          ...heroBannerImgSx,
        }}
      >
        <img src={heroBannerImgSrc} alt="hero banner" />
      </Box>
    </Stack>
  );
};
