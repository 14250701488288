import {css} from "@emotion/css";

export const otherIconStyles = {
  box: css`
    width: 124px;
    height: 124px;
  `,
  bg: css`
    width: 658px;
    height: 429px;
  `,
};
