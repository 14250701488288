import Box from "@mui/material/Box";
import {SxProps, Theme} from "@mui/material";

import {designTokens} from "~/ui";

export interface BmCommonTitleProps {
  className?: string;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

export const BmCommonTitle: React.FC<BmCommonTitleProps> = ({className, sx, children}) => {
  return (
    <Box
      component="h2"
      className={className}
      sx={{
        fontSize: designTokens.fontSize.xl,
        fontWeight: designTokens.fontWeight.bold,
        lineHeight: "2rem",
        marginBottom: 2,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
