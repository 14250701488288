import {css} from "@emotion/css";

export const styles = {
  sidebarSubList: css`
    &.MuiList-root {
      padding-left: 24px;
    }
  `,
  collapse: css`
    margin-bottom: 6px;
  `,
};
