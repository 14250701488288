import {css} from "@emotion/css";

import {designColors, designTokens} from "~/ui";
import {theme} from "~/ui/theme";

export const styles = {
  root: css`
    display: flex;
    align-items: center;
    width: 100%;
    text-decoration: none;
    padding: 8px;

    .MuiListItemIcon-root {
      min-width: 16px !important;
      margin-right: 6px;
    }

    .MuiListItemText-root .list-item-text {
      color: ${designColors.white};
      font-size: ${designTokens.fontSize.sm};
      font-weight: ${designTokens.fontWeight.extraBold};
      line-height: ${designTokens.lineHeight.normal};
      letter-spacing: 0.3px;
    }

    .link-active & {
      background-color: ${theme.palette.brand.light};
      border-radius: 2px;
    }
  `,
};
