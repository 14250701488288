import React, {useMemo} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {SxProps, Theme} from "@mui/material";

import {BmHelperText} from "~/components/helperText";
import {designTokens} from "~/ui";

export interface BmFormTitleProps {
  className?: string;
  sx?: SxProps<Theme>;
  marginBottom?: number;
  subtitle?: string;
  children: React.ReactNode;
}

export const BmFormTitle: React.FC<BmFormTitleProps> = ({className, sx, marginBottom = 2, subtitle, children}) => {
  const Wrapper = useMemo(() => (!!subtitle ? Box : React.Fragment), [subtitle]);
  return (
    <Wrapper>
      <Typography
        variant="h3"
        className={className}
        sx={{
          marginBottom: !!subtitle ? 0 : marginBottom,
          fontSize: designTokens.fontSize.base,
          fontWeight: designTokens.fontWeight.bold,
          lineHeight: designTokens.lineHeight.normal,
          ...sx,
        }}
      >
        {children}
      </Typography>
      {!!subtitle && <BmHelperText sx={{marginBottom}}>{subtitle}</BmHelperText>}
    </Wrapper>
  );
};
