import {SxProps, Theme, Stack} from "@mui/material";

import {ILink} from "../../ILink";
import {BmPrimaryLink} from "../PrimaryLink";

export interface BmPrimaryIconButtonLinkProps {
  sx?: SxProps<Theme>;
  underline?: ILink["underline"];
  onClick?: ILink["onClick"];
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  children: React.ReactNode;
}

export const BmPrimaryIconLinkButton: React.FC<BmPrimaryIconButtonLinkProps> = ({
  sx,
  onClick,
  underline = "none",
  iconLeft,
  iconRight,
  children,
}) => (
  <BmPrimaryLink onClick={onClick} underline={underline} sx={sx}>
    <Stack sx={{alignItems: "center"}} spacing={1} direction="row" useFlexGap>
      {iconLeft}
      {children}
      {iconRight}
    </Stack>
  </BmPrimaryLink>
);
