import Box from "@mui/material/Box";

import {useIsMobile} from "~/hooks";

import {BmTableSorting} from "../TableSorting/TableSorting";

export const BmTablePanel: React.FC = () => {
  const [, isMobile] = useIsMobile();

  return (
    <Box sx={{display: "flex", justifyContent: "flex-end", mb: {xs: 2, md: 0}}}>{isMobile && <BmTableSorting />}</Box>
  );
};
