import {ClientError, GraphQLClient} from "graphql-request";
import {BaseQueryFn} from "@reduxjs/toolkit/dist/query/react";
import {DocumentNode} from "graphql";

export const graphqlRequestBaseQuery = (
  options: {url: string; requestHeaders?: HeadersInit} | {client: GraphQLClient},
): BaseQueryFn<
  {document: string | DocumentNode; variables?: any},
  unknown,
  Pick<ClientError, "name" | "message" | "response">,
  Partial<Pick<ClientError, "request" | "response">>
> => {
  const client = "client" in options ? options.client : new GraphQLClient(options.url);
  if ("requestHeaders" in options) {
    client.setHeaders(options.requestHeaders!);
  }
  return async ({document, variables}) => {
    try {
      return {data: await client.request(document, variables), meta: {}};
    } catch (error) {
      if (error instanceof ClientError) {
        const {name, message, request, response} = error;
        return {
          error: {name, message, response: {status: response.status, errors: response.errors}},
          meta: {request, response},
        };
      }
      throw error;
    }
  };
};
