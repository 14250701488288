import Box from "@mui/material/Box";
import {cx} from "@emotion/css";

import {LayoutType, PageContentWidthVariant, PageContentVariant} from "~/layouts/pageContents/types";
import {BmLayoutSkeleton} from "~/components/skeleton";
import {IBreadcrumbItem} from "~/model/helperTypes/Breadcrumb";

import {ContentHeader} from "./components/ContentHeader/ContentHeader";
import {ContentBody} from "./components/ContentBody/ContentBody";
import {styles} from "./DefaultPageContent.styles";

export interface BmDefaultPageContentProps extends LayoutType {
  breadcrumbs?: IBreadcrumbItem[];
  variant?: PageContentVariant;
  contentWidthVariant?: PageContentWidthVariant;
  zwwTitle?: string; // title that is used for zendesk web widget suggested posts
}

export const BmDefaultPageContent: React.FC<BmDefaultPageContentProps> = ({
  className,
  title,
  zwwTitle,
  subtitle,
  loading,
  breadcrumbs,
  variant = "underlined",
  contentWidthVariant = "fullWidth",
  children,
  statusLabel,
  headerButtons,
}) => {
  return (
    <Box className={cx(styles.root(contentWidthVariant), className)}>
      {/* this section needed for zendesk web widget suggestions */}
      {[zwwTitle, title].some(Boolean) && (
        <Box component="p" sx={{display: "none"}} className="zwwTitle">
          {zwwTitle || title}
        </Box>
      )}
      {loading ? (
        <BmLayoutSkeleton />
      ) : (
        <>
          {title && (
            <ContentHeader
              variant={variant}
              breadcrumbs={breadcrumbs}
              title={title}
              subtitle={subtitle}
              statusLabel={statusLabel}
              headerButtons={headerButtons}
            />
          )}
          <ContentBody>{children}</ContentBody>
        </>
      )}
    </Box>
  );
};
