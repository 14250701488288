import {BmDefaultPageLayout} from "~/layouts/pageLayouts";
import {BmDefaultPageContent} from "~/layouts/pageContents";
import {BmLayoutSkeleton} from "~/components/skeleton";

export const PrivateRouteFallback: React.FC = () => {
  return (
    <BmDefaultPageLayout>
      <BmDefaultPageContent variant="underlined">
        <BmLayoutSkeleton />
      </BmDefaultPageContent>
    </BmDefaultPageLayout>
  );
};
