import {cx} from "@emotion/css";

import {ReactComponent as SvgLogout} from "~/assets/svg/logout.svg";
import {designColors} from "~/ui";

import {simpleIconStyles} from "../styles";
import {commonStyles} from "../../_common/styles";
import {IIconBase} from "../../_common/types";

export const BmLogoutIcon: React.FC<IIconBase> = ({id, size = "sm", color = designColors.black, className}) => {
  return <SvgLogout id={id} className={cx(simpleIconStyles(size), commonStyles.color(color), className)} />;
};
