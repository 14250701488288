import {
  BEAuthenticationErrorType,
  BERegularErrorType,
  BERegularObjectErrorType,
  RESTErrorType,
  BEValidationErrorType,
  DefaultError,
} from "~/model/helperTypes/Errors";
import {AnyObject} from "~/model/helperTypes/generic";

export const isBEValidationError = (error: unknown): error is BEValidationErrorType => {
  return !!((error as any)?.response?.errors || [])[0]?.extensions?.fields?.length;
};

export const isBEAuthenticationError = (error: unknown): error is BEAuthenticationErrorType => {
  const response = error as any;
  if (!response) return false;
  if (!("status" in response)) return false;
  if (!response.data?.errors?.length) return false;
  const {message, path} = response.data.errors[0];
  return typeof message === "string" && typeof path === "string";
};

export const isBERegularError = (error: unknown): error is BERegularErrorType => {
  const response = (error as any)?.response;
  if (!response) return false;
  if (!response.errors?.length) return false;
  const [firstError] = response.errors;
  return typeof firstError.message === "string" && typeof firstError.extensions?.code === "string";
};

export const isBERegularKnownError = (error: BERegularErrorType) => {
  const {extensions, message} = error.response.errors[0];

  switch (true) {
    case extensions.code === "FORBIDDEN" && message === "Shop already has banner":
    case extensions.code === "FORBIDDEN" && message === "Tracezilla order is missing or not confirmed":
    case extensions.code === "NOT_FOUND":
      return true;
    default:
      return false;
  }
};

export const isBERegularObjectError = (error: unknown): error is BERegularObjectErrorType => {
  const response = (error as any)?.response;
  if (!response) return false;
  if (!response.errors?.length) return false;
  const [firstError] = response.errors;
  return (
    firstError.extensions !== undefined &&
    firstError.extensions.code === undefined &&
    typeof firstError.message === "string" &&
    typeof firstError.extensions.problems === "object"
  );
};

export const isRegularRESTError = (error: unknown): error is RESTErrorType => {
  const response = error as any;
  if (!response) return false;
  if (!("status" in response)) return false;
  if (!response.data?.errors?.length) return false;
  const {message} = response.data.errors[0];
  return typeof message === "string";
};

export const isNetworkError = (error: unknown): error is DefaultError => {
  const currentError = error as AnyObject;
  if (!("message" in currentError)) return false;
  if (!("name" in currentError)) return false;
  if (!("stack" in currentError)) return false;
  if (typeof currentError.message !== "string") return false;
  if (typeof currentError.name !== "string") return false;
  return currentError.name === "TypeError" && currentError.message === "Network request failed";
};

export const isReactFloatingError = (error: unknown): error is DefaultError => {
  const currentError = error as AnyObject;
  if (!("message" in currentError)) return false;
  if (!("name" in currentError)) return false;
  if (!("stack" in currentError)) return false;
  if (typeof currentError.message !== "string") return false;
  if (typeof currentError.name !== "string") return false;
  return (
    currentError.name === "NotFoundError" && currentError.message.includes("Failed to execute 'insertBefore' on 'Node'")
  );
};
