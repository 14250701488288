import {cx} from "@emotion/css";

import {ReactComponent as SvgGrayBg} from "~/assets/svg/gray-bg.svg";

import {otherIconStyles} from "../styles";
import {IIconBase} from "../../_common/types";

export const BmGrayBg: React.FC<IIconBase> = ({id, className}) => {
  return <SvgGrayBg id={id} className={cx(otherIconStyles.bg, className)} />;
};
