import {css} from "@emotion/css";

import {designConstants} from "~/ui";
import {theme} from "~/ui/theme";

export const styles = {
  root: css`
    position: relative;
    padding: 24px 12px 72px;
    padding-top: calc(24px + ${designConstants.minHeaderHeight});
    margin: 0 auto;
    width: 100%;

    ${theme.breakpoints.up("sm")} {
      padding: 24px 24px 72px;
      padding-top: calc(24px + ${designConstants.minHeaderHeight});
      max-width: calc(${designConstants.twoColumnsContentWidth} + 48px);
    }

    ${theme.breakpoints.up("lg")} {
      padding: 72px 48px;
      max-width: calc(${designConstants.twoColumnsContentWidth} + 96px);
    }
  `,
};
