import {cx} from "@emotion/css";

import {ReactComponent as SvgDeliveryMan} from "~/assets/svg/delivery-man.svg";
import {designColors} from "~/ui";
import {IIconBase} from "~/components/icons/_common/types";

import {commonStyles} from "../../_common/styles";

interface DeliveryManProps extends Omit<IIconBase, "size"> {}

export const BmDeliveryMan: React.FC<DeliveryManProps> = ({id, className, color = designColors.black}) => {
  return <SvgDeliveryMan id={id} className={cx(commonStyles.color(color), className)} />;
};
