import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

import {useLazyGetLastModifiedParamQuery} from "~/api/manifestApi";
import {useLocalStorage, useInterval} from "~/hooks";

export const useAutomaticRefresh = (): boolean => {
  const {pathname} = useLocation();
  const [storedLastModified, storeLastModified] = useLocalStorage("lastModified");
  const [isOutdated, setIsOutdated] = useState(false);

  const [getLastModifiedParam, {data}] = useLazyGetLastModifiedParamQuery();

  const interval = useInterval(() => getLastModifiedParam(), 60 * 1000);

  useEffect(() => {
    if (!data?.lastModified) return;

    if (!storedLastModified) {
      storeLastModified(data.lastModified);
    } else if (storedLastModified !== data.lastModified) {
      storeLastModified(data.lastModified);
      setIsOutdated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    getLastModifiedParam();
    interval.start();
    return () => interval.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return isOutdated;
};
