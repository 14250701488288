import Grid from "@mui/material/Grid";
import {GridSize} from "@mui/material/Grid/Grid";

export interface BmInfoPageLayoutProps {
  children: React.ReactNode;
  gridSize?: GridSize;
}

export const BmInfoPageLayout: React.FC<BmInfoPageLayoutProps> = ({children, gridSize = 6}) => (
  <Grid container justifyContent="center">
    <Grid item xs={12} md={gridSize} sx={{padding: "4rem 2rem"}}>
      {children}
    </Grid>
  </Grid>
);
