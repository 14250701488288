import {cx} from "@emotion/css";
import {Box, SxProps, Theme} from "@mui/material";
import React from "react";

import {styles} from "./UnstyledBaseButton.styles";

export interface UnstyledBaseButtonProps {
  id: string;
  className?: string;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
  onClick: React.MouseEventHandler;
  children?: React.ReactNode;
}

export const BmUnstyledBaseButton = React.forwardRef<
  HTMLButtonElement,
  UnstyledBaseButtonProps & React.HTMLProps<HTMLButtonElement>
>(function UnstyledBaseButton({id, className, sx, fullWidth, onClick, children, ...restProps}, ref) {
  return (
    <Box
      {...restProps}
      ref={ref}
      component="button"
      type="button"
      id={id}
      className={cx(styles.default, fullWidth && styles.fullWidth, className)}
      sx={sx}
      onClick={onClick}
    >
      {children &&
        React.Children.map(children, (child) => {
          if (!child) return;
          return child;
        })}
    </Box>
  );
});
