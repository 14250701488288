import {SxProps, Theme, Typography} from "@mui/material";

export interface BmCommonTitleSmallProps {
  className?: string;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

export const BmCommonTitleSmall: React.FC<BmCommonTitleSmallProps> = ({className, sx, children}) => {
  return (
    <Typography variant="h3" className={className} sx={{mb: 0, ...sx}}>
      {children}
    </Typography>
  );
};
