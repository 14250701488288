import {Maybe} from "~/api/generated";

export const enhanceImageUrl = (originUrl?: Maybe<string>, {noTransformations = false} = {}): string | undefined => {
  if (!originUrl) return;

  let transformations = "";

  if (!noTransformations) {
    transformations += "-/quality/smart_retina/";
  }

  return `https://227602d2647c3e20bd63.ucr.io/${transformations}${originUrl}`;
};
