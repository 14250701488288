import {cx} from "@emotion/css";

import {ReactComponent as SvgCheckboxChecked} from "~/assets/svg/checkbox-checked.svg";
import {designColors} from "~/ui";

import {IIconBase} from "../../_common/types";
import {commonStyles} from "../../_common/styles";
import {simpleIconStyles} from "../styles";

export const BmCheckboxCheckedIcon: React.FC<IIconBase> = ({
  id,
  size = "lg",
  color = designColors.primary,
  className,
}) => {
  return (
    <SvgCheckboxChecked id={id} className={cx(simpleIconStyles(size), commonStyles.fillColor(color), className)} />
  );
};
