import {BmPrimaryLink} from "../PrimaryLink";

export interface BmSectionTitleButtonProps {
  onClick?: VoidFunction;
  children: React.ReactNode;
}

export const BmSectionTitleButton: React.FC<BmSectionTitleButtonProps> = ({onClick, children}) => (
  <BmPrimaryLink weight="semiBold" underline="none" onClick={onClick}>
    {children}
  </BmPrimaryLink>
);
