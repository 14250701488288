import Box from "@mui/material/Box";

import {BmSimplePageLayout} from "~/layouts/pageLayouts";
import {BmLayoutSkeleton} from "~/components/skeleton";
import {BmSimplePageContent} from "~/layouts/pageContents";

export const GuestRouteFallback: React.FC = () => {
  return (
    <BmSimplePageLayout>
      <BmSimplePageContent>
        <Box sx={{width: "100%", maxWidth: "600px"}}>
          <BmLayoutSkeleton />
        </Box>
      </BmSimplePageContent>
    </BmSimplePageLayout>
  );
};
