import React from "react";
import ReactDOM from "react-dom";
import smoothscroll from "smoothscroll-polyfill";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

import App from "./App";
import "./modules/i18n/setup";
import reportWebVitals from "./reportWebVitals";
import "./ui/globalStyles";
import "./utils/disableWarnings";

process.env.REACT_APP_SENTRY_DSN &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    ignoreErrors: [/Failed to execute .?insertBefore.? on .?Node.?/i],
  });

// kick off the polyfill!
smoothscroll.polyfill();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
