import {css} from "@emotion/css";

import {designColors, designConstants} from "~/ui";

export const styles = {
  sidebar: css`
    flex-shrink: 0;
    width: ${designConstants.sidebarWidth};

    .MuiPaper-root.MuiDrawer-paper {
      width: ${designConstants.sidebarWidth};
      height: 100%;

      padding-left: 20px;
      padding-right: 20px;
      background-color: ${designColors.brand};
    }
  `,
  logoLink: css`
    display: flex;
    padding: 24px 0;
  `,
  logo: css`
    max-width: 117px;
  `,
};
