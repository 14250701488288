import {cx} from "@emotion/css";

import {ReactComponent as SvgNewLogo} from "~/assets/svg/logo-new.svg";
import {designColors} from "~/ui";
import {commonStyles} from "~/components/icons/_common/styles";

import {IIconBase} from "../../../_common/types";
import {styles} from "./styles";

interface NewLogoProps extends Omit<IIconBase, "size"> {}

export const BmNewLogo: React.FC<NewLogoProps> = ({id, className, color = designColors.black}) => {
  return <SvgNewLogo id={id} className={cx(styles.root, commonStyles.color(color), className)} />;
};
