import {Box, Stack, SxProps, Theme, Typography} from "@mui/material";
import {useMemo} from "react";

import {designColors, designConstants, getPaddingBySpacingSize, SpacingSize} from "~/ui";
import {BmLabel} from "~/components/labels";

import {getColorsByPreset} from "../_common/helpers";
import {BannerColorPresetType, IBannerColors} from "../_common/types";

export interface BmSimpleBaseInfoBannerProps {
  id?: string;
  className?: string;
  sx?: SxProps<Theme>;
  colorPreset?: BannerColorPresetType;
  spacingSize?: SpacingSize;
  title?: React.ReactNode;
  description?: string;
  leftColumn?: React.ReactNode;
  customColors?: Partial<IBannerColors>;
}

export const BmSimpleBaseInfoBanner: React.FC<BmSimpleBaseInfoBannerProps> = ({
  id,
  className,
  sx,
  colorPreset = "grey",
  spacingSize = "md",
  title,
  description,
  leftColumn,
  customColors,
}) => {
  const {backgroundColor, borderColor, titleColor} = useMemo(
    () => ({...getColorsByPreset(colorPreset), ...(customColors || {})}),
    [customColors, colorPreset],
  );

  return (
    <Box
      sx={{
        p: getPaddingBySpacingSize(spacingSize),
        backgroundColor,
        border: `1px solid ${borderColor}`,
        borderRadius: designConstants.borderRadius,
        ...sx,
      }}
      className={className}
      id={id}
    >
      <Stack direction="row" alignItems="start" spacing={2} useFlexGap>
        {leftColumn && <Box>{leftColumn}</Box>}
        <Stack direction="row" flexWrap="wrap" spacing={1} useFlexGap>
          {title && (
            <BmLabel variant="semiBold" sx={{color: titleColor}}>
              {title}
            </BmLabel>
          )}
          {description && (
            <Typography
              variant="body0"
              dangerouslySetInnerHTML={{__html: description}}
              sx={{"& *": {color: designColors.colorInk}}}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
