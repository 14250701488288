import {css} from "@emotion/css";

export const styles = {
  contextMenuButton: css`
    svg {
      width: 20px;
      height: 20px;
    }
  `,
};
