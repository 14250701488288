import {BmAlertIcon} from "~/components/icons/simpleIcons";
import {designColors} from "~/ui";

import {BmSimpleBaseInfoBanner, BmSimpleBaseInfoBannerProps} from "../../SimpleBaseInfoBanner";

export const BmSimpleErrorNotificationBanner: React.FC<BmSimpleBaseInfoBannerProps> = ({...restProps}) => {
  return (
    <BmSimpleBaseInfoBanner
      {...restProps}
      colorPreset="red"
      leftColumn={<BmAlertIcon id="alert-icon" color={designColors.colorInk} size="xs" />}
    />
  );
};
