import {isSafari} from "react-device-detect";
import {css} from "@emotion/css";

import {SizeType} from "../_common/types";
import {getMeasureDependsOnSize} from "./utils";

export const simpleIconStyles = (size: SizeType) => css`
  width: ${getMeasureDependsOnSize(size)}px;
  height: ${getMeasureDependsOnSize(size)}px;
  ${isSafari &&
  css`
    transform: translate3d(0, 0, 0);
  `}
`;
