import {NavLink as RouterLink, useRouteMatch} from "react-router-dom";
import * as History from "history";

import {styles} from "./SidebarSubItem.styles";
import {SidebarItem} from "../SidebarItem/SidebarItem";
import {SidebarItemContent} from "../SidebarItemContent/SidebarItemContent";

type SidebarSubItemProps = {
  disabled?: boolean;
  title: string;
  to: string | ((location: History.Location) => string);
  path: string;
};

export const SidebarSubItem: React.FC<SidebarSubItemProps> = ({disabled, title, to, path}) => {
  const matchPath = useRouteMatch(path);

  return (
    <SidebarItem disabled={disabled}>
      <RouterLink
        to={to}
        isActive={(match) => {
          if (match?.isExact) return true;
          if (matchPath?.isExact) return true;
          return false;
        }}
        activeClassName="link-active"
        className={styles.sidebarSubItemLink}
      >
        <SidebarItemContent text={title} className={styles.sidebarSubItemContent} />
      </RouterLink>
    </SidebarItem>
  );
};
