/* eslint-disable no-console */
import {SerializedError} from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import {ClientError} from "graphql-request";
import {useCallback, useEffect, useState} from "react";

import {
  BEAuthenticationErrorType,
  BERegularErrorType,
  BERegularObjectErrorType,
  BEValidationErrorType,
  FieldErrorType,
  DefaultError,
} from "~/model/helperTypes/Errors";
import {
  isBEAuthenticationError,
  isBERegularError,
  isBERegularKnownError,
  isBERegularObjectError,
  isBEValidationError,
  isNetworkError,
  isReactFloatingError,
} from "~/utils/errors";
import {errorToast} from "~/utils/toasts/errorToast";

export type ErrorType = Pick<ClientError, "name" | "message" | "response"> | SerializedError;
type ErrorHandlerType = (error: ErrorType) => void;

export const useErrorCatch = (
  error?: ErrorType,
): [FieldErrorType[] | null, {errorHandler: ErrorHandlerType; cleanErrors: () => void}] => {
  const [errors, setErrors] = useState<FieldErrorType[] | null>(null);

  const errorHandler = useCallback<ErrorHandlerType>((error) => {
    // in order to make errors tracking work
    setErrors(null);
    switch (true) {
      case isBEValidationError(error): {
        const currentError = error as BEValidationErrorType;
        setErrors(currentError.response.errors[0].extensions.fields);
        console.error("Backend validation error:", currentError);
        break;
      }
      case isBEAuthenticationError(error): {
        const currentError = error as BEAuthenticationErrorType;
        setErrors(currentError.data.errors.map(({path, message}) => ({field: path, message})));
        console.error("Backend authentication error:", currentError);
        break;
      }
      case isBERegularError(error): {
        const currentError = error as BERegularErrorType;
        setErrors(null);
        errorToast(
          `Error message: ${currentError.response.errors[0].message};<br />Error code: ${currentError.response.errors[0].extensions.code}`,
        );
        if (!isBERegularKnownError(currentError)) {
          Sentry.captureException({...currentError, errorType: "BERegularUnknownError"});
        }
        console.error("Backend regular error:", currentError);
        break;
      }
      case isBERegularObjectError(error): {
        const currentError = error as BERegularObjectErrorType;
        setErrors(null);
        errorToast(`Error message: ${currentError.response.errors[0].message}`);
        Sentry.captureException({...currentError, errorType: "BERegularObjectError"});
        console.error("Backend regular object error:", currentError);
        break;
      }
      case isNetworkError(error): {
        const currentError = error as DefaultError;
        setErrors(null);
        errorToast(`${currentError.message}. Please, reload the page!`);
        console.error("Network error:", currentError);
        break;
      }
      case isReactFloatingError(error): {
        const currentError = error as DefaultError;
        setErrors(null);
        console.error("React floating error:", currentError);
        break;
      }
      default:
        setErrors(null);
        errorToast(`Error: ${JSON.stringify(error)}`);
        Sentry.captureException({...error, errorType: "UnknownError"});
        console.error("Unknown error:", error);
        break;
    }
  }, []);

  const cleanErrors = useCallback(() => {
    setErrors(null);
  }, []);

  useEffect(() => {
    if (!error) return;
    errorHandler(error);
  }, [error, errorHandler]);

  return [errors, {errorHandler, cleanErrors}];
};
