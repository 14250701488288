import {useEffect, useState} from "react";

const refreshEditableCellsTabIndex = (editableCells: Element[] | null) => {
  editableCells?.forEach((cell) => {
    if (cell.getAttribute("tabindex") === "0") return;
    cell.setAttribute("tabindex", "0");
  });
};

export const useKeepTabIndex = () => {
  const [editableCells, setEditableCells] = useState<Element[] | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (editableCells) return;
    const tempEditableCells = [...document.querySelectorAll(".MuiDataGrid-cell--editable")];
    if (tempEditableCells.length === 0) return;
    setEditableCells(tempEditableCells);
  });

  useEffect(() => {
    if (!editableCells) return;
    const intervalId = setInterval(() => refreshEditableCellsTabIndex(editableCells), 300);
    return () => clearInterval(intervalId);
  }, [editableCells]);
};
