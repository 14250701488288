import {createSlice} from "@reduxjs/toolkit";

import {ILocalBeverage} from "~/model/DAO/IBeverage";

import {resetAll} from "./global";

export interface LocalBeverageState {
  localBeverage: ILocalBeverage;
  shouldCreateNewOnSubmit: boolean;
}

const initialState: LocalBeverageState = {
  localBeverage: {} as ILocalBeverage,
  shouldCreateNewOnSubmit: false,
};

export const LocalBeverageSlice = createSlice({
  name: "localBeverage",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setStep: (state, action) => ({
      ...state,
      localBeverage: {
        ...state.localBeverage,
        ...action.payload,
      },
    }),
    resetAllSteps: (state) => ({
      ...state,
      localBeverage: {...initialState.localBeverage},
    }),
    toggleShouldCreateNewOnSubmit: (state) => ({
      ...state,
      shouldCreateNewOnSubmit: !state.shouldCreateNewOnSubmit,
    }),
  },
});

export const LocalBeverageActions = LocalBeverageSlice.actions;

export default LocalBeverageSlice.reducer;
