import {css} from "@emotion/css";

import {designColors} from "~/ui";

export const styles = {
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
    background-color: ${designColors.white};
    border-bottom: 1px solid ${designColors.grey_2};
  `,
};
