import MuiSkeleton from "@mui/material/Skeleton";

import {styles} from "./Skeleton.styles";

export interface BmSkeletonProps {
  height?: number;
}

export const BmSkeleton: React.FC<BmSkeletonProps> = ({height}) => {
  return <MuiSkeleton className={styles.skeleton} height={height} />;
};
