import {cx} from "@emotion/css";

import {designColors} from "~/ui";
import {ReactComponent as SvgDownload} from "~/assets/svg/download.svg";

import {simpleIconStyles} from "../styles";
import {commonStyles} from "../../_common/styles";
import {IIconBase} from "../../_common/types";

export const BmDownloadIcon: React.FC<IIconBase> = ({id, size = "xs", color = designColors.black, className}) => {
  return <SvgDownload id={id} className={cx(simpleIconStyles(size), commonStyles.color(color), className)} />;
};
