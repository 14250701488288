import {createSlice} from "@reduxjs/toolkit";

import {ShippingAddressSummaryFragment} from "~/api/generated";
import {ILocalCustomer} from "~/model/DAO/ICustomer";

import {resetAll} from "./global";

export interface CustomerState {
  localCustomer: ILocalCustomer;
  shouldCreateNewOnSubmit: boolean;
}

const initialState: CustomerState = {
  localCustomer: {} as ILocalCustomer,
  shouldCreateNewOnSubmit: false,
};

export const LocalCustomerSlice = createSlice({
  name: "localCustomer",
  initialState,
  extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setLocalCustomer: (state, action) => {
      const {mailingAddresses} = action.payload;
      let newLocalCustomer = {
        ...state.localCustomer,
        ...action.payload,
      };
      if (mailingAddresses)
        newLocalCustomer = {
          ...newLocalCustomer,
          mailingAddresses: mailingAddresses.map((el: ShippingAddressSummaryFragment) => ({...el})),
        };
      return {
        ...state,
        localCustomer: newLocalCustomer,
      };
    },
    resetLocalCustomer: (state) => ({
      ...state,
      localCustomer: {...initialState.localCustomer},
    }),
    toggleShouldCreateNewOnSubmit: (state) => ({
      ...state,
      shouldCreateNewOnSubmit: !state.shouldCreateNewOnSubmit,
    }),
  },
});

export const LocalCustomerActions = LocalCustomerSlice.actions;

export default LocalCustomerSlice.reducer;
