import {css} from "@emotion/css";
import {rgba} from "polished";

import {designColors, designTokens} from "~/ui";

export const styles = {
  root: (inactive?: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    background-color: ${inactive ? rgba(designColors.black, 0.6) : designColors.primary};

    color: ${designColors.white};
    font-size: ${designTokens.fontSize.sm};
    font-weight: ${designTokens.fontWeight.semiBold};
  `,
};
