import {css} from "@emotion/css";

import {theme} from "~/ui/theme";
import {designColors, designConstants} from "~/ui";

export const styles = {
  root: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${designConstants.minHeaderHeight};
    padding: 18px 12px;
    background-color: ${designColors.brand};

    ${theme.breakpoints.up("sm")} {
      padding: 18px 24px;
    }

    ${theme.breakpoints.up("md")} {
      padding: 18px 168px;
    }
  `,
  logo: css`
    max-width: 117px;
  `,
};
