import {css} from "@emotion/css";

import {designColors, designTokens, designUtils} from "~/ui";

export const styles = {
  link: (isCallbackSet: boolean) => css`
    &.MuiLink-root {
      ${designUtils.baseFontStyles};
      display: inline-block;
      text-decoration-color: ${designColors.primary};

      ${isCallbackSet &&
      css`
        cursor: pointer;
      `}
    }
  `,
  weight: (value: keyof typeof designTokens.fontWeight) => css`
    &.MuiLink-root {
      font-weight: ${designTokens.fontWeight[value]};
    }
  `,
};
