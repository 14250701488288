import {css} from "@emotion/css";

import {theme} from "~/ui/theme";
import {designColors} from "~/ui";
import {PageContentVariant} from "~/layouts/pageContents/types";

export const styles = {
  root: (variant: PageContentVariant) => css`
    ${{
      plain: css`
        margin-bottom: 24px;
      `,
      underlined: css`
        margin-bottom: 24px;
        padding-bottom: 12.5px;
        border-bottom: 1px solid ${designColors.grey_2};
      `,
      tabulated: css`
        margin-bottom: 24px;

        ${theme.breakpoints.up("md")} {
          margin-bottom: 15px;
        }
      `,
    }[variant]}
  `,
  headerMainContent: css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 12px;

    ${theme.breakpoints.up("md")} {
      align-items: center;
      flex-direction: row;
    }
  `,
};
