import {cx} from "@emotion/css";

import {ReactComponent as SvgBox} from "~/assets/svg/box.svg";

import {otherIconStyles} from "../styles";
import {IIconBase} from "../../_common/types";

export const BmCartonBox: React.FC<IIconBase> = ({id, className}) => {
  return <SvgBox id={id} className={cx(otherIconStyles.box, className)} />;
};
