import {RefreshBanner} from "~/components/banners";

import {OnboardingModal} from "./OnboardingModal";

export const PrivateModals: React.FC = () => {
  return (
    <>
      <OnboardingModal />
      <RefreshBanner />
    </>
  );
};
