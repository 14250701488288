import {SizeType} from "../_common/types";

export const getMeasureDependsOnSize = (size: SizeType) => {
  switch (size) {
    case "xs":
      return 16;
    case "sm":
      return 18;
    case "md":
      return 20;
    case "lg":
      return 24;
    case "xl":
    default:
      return 38;
  }
};
