import React from "react";
import Box from "@mui/material/Box";

import {BmArrowPrimaryButton} from "~/components/buttons";

import {IContextMenu} from "../../../IContextMenu";
import {BmContextMenu} from "../../ContextMenu";

export const BmContextMenuWithArrowButton: React.FC<Omit<IContextMenu, "icon">> = ({
  className,
  buttonText,
  isLoading,
  onClick,
  onClose,
  children,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onClick?.();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    onClose?.();
    setAnchorEl(null);
  };

  return (
    <Box className={className}>
      <BmArrowPrimaryButton isOpen={open} onClick={handleClick}>
        {buttonText}
      </BmArrowPrimaryButton>
      <BmContextMenu open={open} anchorEl={anchorEl} handleClose={handleClose} isLoading={isLoading}>
        {React.Children.map(children, (child) => {
          if (!child) return;
          return child;
        })}
      </BmContextMenu>
    </Box>
  );
};
