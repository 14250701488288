import {cx} from "@emotion/css";
import {Box, Stack, Typography} from "@mui/material";
import React from "react";

import {BmBlocksIcon} from "~/components/icons/simpleIcons";
import {BmLabel} from "~/components/labels";
import {BmLayoutSkeleton} from "~/components/skeleton";
import {designColors, designTokens} from "~/ui";

import {styles} from "./TracezillaPageContent.styles";

interface BmTracezillaPageContentProps {
  className?: string;
  loading?: boolean;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  zwwTitle?: string; // title that is used for zendesk web widget suggested posts
  children: React.ReactNode;
}

export const BmTracezillaPageContent: React.FC<BmTracezillaPageContentProps> = ({
  className,
  loading,
  title,
  subtitle,
  zwwTitle,
  children,
}) => {
  return (
    <Box className={cx(styles.root, className)}>
      {/* this section needed for zendesk web widget suggestions */}
      {[zwwTitle, title].some(Boolean) && (
        <Box component="p" sx={{display: "none"}} className="zwwTitle">
          {zwwTitle || title}
        </Box>
      )}
      {loading ? (
        <BmLayoutSkeleton />
      ) : (
        <Stack spacing={4} useFlexGap>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 48,
              height: 48,
              background: designColors.blue_1,
              borderRadius: "3px",
            }}
          >
            <BmBlocksIcon id="header-block-icons" />
          </Box>
          {[title, subtitle].some(Boolean) && (
            <Stack spacing={1} useFlexGap>
              {title && (
                <Typography
                  component="h1"
                  sx={{
                    m: 0,
                    fontSize: designTokens.fontSize.xl,
                    fontWeight: designTokens.fontWeight.bold,
                    lineHeight: "30px",
                  }}
                >
                  {title}
                </Typography>
              )}
              {subtitle && <BmLabel>{subtitle}</BmLabel>}
            </Stack>
          )}
          <Box>{children}</Box>
        </Stack>
      )}
    </Box>
  );
};
